//import { parse, type Schema, stringify } from "bcp-47";
import { KnownLanguages } from "./KnownLanguages";

export interface LanguageJson {
  fullTag: string;
  primaryTag: string;
  names: Record<string, string>;
}

/**
 * This class tries to be similar to `Language.java` as far as it is practical.
 *
 * @class Language
 */
export class Language {
  public static EN: Language = new Language("en");
  public static EN_US: Language = new Language("en-US");
  public static DE: Language = new Language("de");

  private static values: Map<String, Language> = new Map([
    ["en", Language.EN],
    ["en-US", Language.EN_US],
    ["de", Language.DE],
  ]);

  tag: string;

  //Hidden because we want to ensure that we have singletons
  private constructor(languageTag: string) {
    // call toString to make sure that we don't use a string-like object, e.g. another
    // Language instance that was passed from an older version if this library, etc.
    // This call has the positive side effect that passing a nullish languageTag will instantly fail.
    this.tag = languageTag.toString();
  }

  public static orNull(languageTag: string): Language | null {
    if (languageTag == null) {
      return null;
    }

    var lang = Language.values.get(languageTag);
    if (lang != undefined) {
      return lang;
    }

    var normalized = Language.normalize(languageTag);
    if (normalized == null) {
      //invalid language tag
      return null;
    }

    if (normalized != languageTag) {
      lang = Language.values.get(normalized);
      if (lang != undefined) {
        return lang;
      }
    }

    lang = new Language(normalized);
    Language.values.set(normalized, lang);

    return lang;
  }

  /**
	 * Fetches a language instance corresponding to a language string. The returned language instances are unique and can 
	 * be compared using the == operator. Use {@link #orNull(String)} or add a default return value with 
   * {@link #from(String, Language)} to avoid that an exception is thrown for invalid language tags.  
	 * @param languageTag A valid language string. May not be null or empty. Not case sensitive. 'en-US', 'en-us', 'EN-us' etc. are all valid.
	 * @param defaultLanguage The language that should be assigned if the provided languageTag is not valid, null or empty 
	   @return The language instance or the default language (if specified) 
	 * @throws IllegalArgumentException If the source parameter is not recognized as something consisting of language followed optionally by region (and no defaultLanguage is defined). */
  public static from(
    languageTag: string,
    defaultLanguage?: Language
  ): Language {
    var lang: Language | null = Language.orNull(languageTag);
    if (lang == null) {
      if (defaultLanguage != undefined) {
        return defaultLanguage;
      }
      throw new Error("Invalid language tag: " + languageTag);
    } else {
      return lang;
    }
  }

  /** Attempts to normalize the string */
  private static normalize(languageTag: string): string | null {
    var result: string | null = null;
    var idx: number = languageTag.indexOf("-");
    if (idx < 0) {
      result = Language.normalizePrimaryTag(languageTag);
    } else {
      result = Language.normalizePrimaryTag(languageTag.substring(0, idx));
      if (result != null) {
        var rest: string = languageTag.substring(idx + 1);
        //example for a variant : eo-xsistemo (used by Liblouis for esperanto)
        if (rest.length <= 3) {
          //primitive way of ignoring variants, scripts, private tags etc.
          var region: string | null = Language.normalizeRegionTag(rest);
          result = region != null ? result + "-" + region : null;
        }
      }
    }

    return result;
  }

  private static normalizePrimaryTag(primary: string): string | null {
    var result: string = primary.toLowerCase();
    if (primary.length < 2 || primary.length > 3) {
      return null;
    }
    for (var i: number = 0; i < result.length; i++) {
      if (result.charAt(i) < "a" || result.charAt(i) > "z") {
        return null;
      }
    }

    if ("no" == result) {
      //no is not a valid language identifier but it is used by some services for Norwegian (Bokmal)
      return "nb";
    }

    return result;
  }

  private static normalizeRegionTag(region: string): string | null {
    var result = region.toUpperCase();
    if (result.length < 2 || result.length > 3) {
      return null;
    }
    for (var i: number = 0; i < result.length; i++) {
      //check if value consists of letters or digits
      if (result.charAt(i) < "A") {
        if (result.charAt(i) < "0" || result.charAt(i) > "9") {
          return null;
        }
      } else if (result.charAt(i) > "Z") {
        return null;
      }
    }

    if ("UK" == result) {
      //en-UK is not a valid tag; en-GB must be used instead (politically GB excludes Northern Ireland, but GB is english as spoken in Great Britain which is the same as english spoken in GreatBritain and Northern Ireland..)
      return "GB";
    }

    return result;
  }

  /** @return the two digit or three digit language code (the rfc calls this the 'primary' language subtag)
   * Examples are 'de', 'fr', 'cmn' (Mandarin) */
  public getPrimary(): string {
    var idx: number = this.tag.indexOf("-");
    return idx < 0 ? this.toString() : this.tag.substring(0, idx);
  }

  /** @deprecated use getPrimary() instead */
  public getPrimaryTag(): string {
    return this.getPrimary();
  }

  /** Whether this language has a region subtag */
  public hasRegion(): boolean {
    return this.tag.indexOf("-") >= 0;
  }

  /** @return An empty string or the region subtag (such es 'DE', 'FR', 'US' (may also have 3 letters) */
  public getRegion(): string {
    var idx: number = this.tag.indexOf("-");
    return idx >= 0 ? this.tag.substring(idx + 1) : "";
  }

  /** Expands the language with default region information (if this language has no region).
   *  For example, expands 'en' to 'en-US' or 'nb' to 'nb-NO'.
   *  This is the opposite of {@link #asPrimary()}.
   *  Warning: If it can not find the default region for some language, it logs a warning and then it
   *  just returns en-US to ensure that always a valid language code with a valid region is returned
   *  (even if it is not the one that was wanted)!
   *  @return A language that has the primary language tag and a region code  */
  public expand(): Language {
    if (this.hasRegion()) {
      return this;
    }

    var lang = KnownLanguages.expandLanguage(this);
    if (lang == null) {
      //Nothing found, too bad
      console.warn(
        "could not find default language and region code for: " + this
      );
      return Language.EN_US;
    }

    return lang;
  }

  /**
   * Converts this Language into a Language without region information, that is into a region for
   * which {@link #hasRegion()} is false. This is the opposite of {@link #expand()}.
   * @return A language that has only the primary language tag but no region */
  public asPrimary(): Language {
    return this.hasRegion() ? Language.from(this.getPrimary()) : this;
  }

  /** @deprecated use asPrimary() instead */
  public getPrimaryLanguage(): Language {
    return this.asPrimary();
  }

  public isPrimary(): boolean {
    return this.tag.indexOf("-") < 0;
  }

  public display(displayLanguage: Language = Language.EN): string {
    return KnownLanguages.display(this, displayLanguage);
  }

  public toString(): string {
    return this.tag;
  }

  public toJSON(): string {
    return this.tag;
  }
}
