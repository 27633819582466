const servicesAndLanguages = 
{
    "languages": {
        "af": {
            "fullTag": "af",
            "primaryTag": "af",
            "names": {
                "de": "Afrikaans",
                "en": "Afrikaans"
            }
        },
        "af-ZA": {
            "fullTag": "af-ZA",
            "primaryTag": "af",
            "names": {
                "de": "Afrikaans (Südafrika)",
                "en": "Afrikaans (South Africa)"
            }
        },
        "am": {
            "fullTag": "am",
            "primaryTag": "am",
            "names": {
                "de": "Amharisch",
                "en": "Amharic"
            }
        },
        "am-ET": {
            "fullTag": "am-ET",
            "primaryTag": "am",
            "names": {
                "de": "Amharisch (Äthiopien)",
                "en": "Amharic (Ethiopia)"
            }
        },
        "ar": {
            "fullTag": "ar",
            "primaryTag": "ar",
            "names": {
                "de": "Arabisch",
                "en": "Arabic"
            }
        },
        "ar-XA": {
            "fullTag": "ar-XA",
            "primaryTag": "ar",
            "names": {
                "de": "Arabisch (Modernes Hocharabisch)",
                "en": "Arabic (Modern Standard Arabic)"
            }
        },
        "as": {
            "fullTag": "as",
            "primaryTag": "as",
            "names": {
                "de": "Assamesisch",
                "en": "Assamese"
            }
        },
        "aw": {
            "fullTag": "aw",
            "primaryTag": "aw",
            "names": {
                "de": "aw",
                "en": "aw"
            }
        },
        "az": {
            "fullTag": "az",
            "primaryTag": "az",
            "names": {
                "de": "Aserbeidschanisch",
                "en": "Azeri"
            }
        },
        "ba": {
            "fullTag": "ba",
            "primaryTag": "ba",
            "names": {
                "de": "Baschkirisch",
                "en": "Bashkir"
            }
        },
        "be": {
            "fullTag": "be",
            "primaryTag": "be",
            "names": {
                "de": "Weißrussisch",
                "en": "Belarusian"
            }
        },
        "bg": {
            "fullTag": "bg",
            "primaryTag": "bg",
            "names": {
                "de": "Bulgarisch",
                "en": "Bulgarian"
            }
        },
        "bg-BG": {
            "fullTag": "bg-BG",
            "primaryTag": "bg",
            "names": {
                "de": "Bulgarisch (Bulgarien)",
                "en": "Bulgarian (Bulgaria)"
            }
        },
        "bh": {
            "fullTag": "bh",
            "primaryTag": "bh",
            "names": {
                "de": "Biharisch",
                "en": "Bihari"
            }
        },
        "bn": {
            "fullTag": "bn",
            "primaryTag": "bn",
            "names": {
                "de": "Bengalisch",
                "en": "Bangla"
            }
        },
        "bn-IN": {
            "fullTag": "bn-IN",
            "primaryTag": "bn",
            "names": {
                "de": "Bengalisch (Indien)",
                "en": "Bangla (India)"
            }
        },
        "bo": {
            "fullTag": "bo",
            "primaryTag": "bo",
            "names": {
                "de": "Tibetisch",
                "en": "Tibetan"
            }
        },
        "br": {
            "fullTag": "br",
            "primaryTag": "br",
            "names": {
                "de": "Bretonisch",
                "en": "Breton"
            }
        },
        "bs": {
            "fullTag": "bs",
            "primaryTag": "bs",
            "names": {
                "de": "Bosnisch",
                "en": "Bosnian"
            }
        },
        "ca": {
            "fullTag": "ca",
            "primaryTag": "ca",
            "names": {
                "de": "Katalanisch",
                "en": "Catalan"
            }
        },
        "ca-ES": {
            "fullTag": "ca-ES",
            "primaryTag": "ca",
            "names": {
                "de": "Katalanisch (Spanien)",
                "en": "Catalan (Spain)"
            }
        },
        "chr": {
            "fullTag": "chr",
            "primaryTag": "chr",
            "names": {
                "de": "Cherokee",
                "en": "Cherokee"
            }
        },
        "ckb": {
            "fullTag": "ckb",
            "primaryTag": "ckb",
            "names": {
                "de": "Zentralkurdisch",
                "en": "Soranî"
            }
        },
        "cmn": {
            "fullTag": "cmn",
            "primaryTag": "cmn",
            "names": {
                "de": "Mandarin",
                "en": "Mandarin"
            }
        },
        "cmn-CN": {
            "fullTag": "cmn-CN",
            "primaryTag": "cmn",
            "names": {
                "de": "Mandarin (China)",
                "en": "Mandarin (China)"
            }
        },
        "cmn-TW": {
            "fullTag": "cmn-TW",
            "primaryTag": "cmn",
            "names": {
                "de": "Mandarin (Taiwan)",
                "en": "Mandarin (Taiwan)"
            }
        },
        "cop": {
            "fullTag": "cop",
            "primaryTag": "cop",
            "names": {
                "de": "Koptisch",
                "en": "Coptic"
            }
        },
        "cs": {
            "fullTag": "cs",
            "primaryTag": "cs",
            "names": {
                "de": "Tschechisch",
                "en": "Czech"
            }
        },
        "cs-CZ": {
            "fullTag": "cs-CZ",
            "primaryTag": "cs",
            "names": {
                "de": "Tschechisch (Tschechien)",
                "en": "Czech (Czechia)"
            }
        },
        "cy": {
            "fullTag": "cy",
            "primaryTag": "cy",
            "names": {
                "de": "Walisisch",
                "en": "Welsh"
            }
        },
        "da": {
            "fullTag": "da",
            "primaryTag": "da",
            "names": {
                "de": "Dänisch",
                "en": "Danish"
            }
        },
        "da-DK": {
            "fullTag": "da-DK",
            "primaryTag": "da",
            "names": {
                "de": "Dänisch (Dänemark)",
                "en": "Danish (Denmark)"
            }
        },
        "de": {
            "fullTag": "de",
            "primaryTag": "de",
            "names": {
                "de": "Deutsch",
                "en": "German"
            }
        },
        "de-DE": {
            "fullTag": "de-DE",
            "primaryTag": "de",
            "names": {
                "de": "Deutsch (Deutschland)",
                "en": "German (Germany)"
            }
        },
        "dra": {
            "fullTag": "dra",
            "primaryTag": "dra",
            "names": {
                "de": "Drawidisch",
                "en": "Dravidian"
            }
        },
        "el": {
            "fullTag": "el",
            "primaryTag": "el",
            "names": {
                "de": "Griechisch",
                "en": "Greek"
            }
        },
        "el-GR": {
            "fullTag": "el-GR",
            "primaryTag": "el",
            "names": {
                "de": "Griechisch (Griechenland)",
                "en": "Greek (Greece)"
            }
        },
        "en": {
            "fullTag": "en",
            "primaryTag": "en",
            "names": {
                "de": "Englisch",
                "en": "English"
            }
        },
        "en-AU": {
            "fullTag": "en-AU",
            "primaryTag": "en",
            "names": {
                "de": "Englisch (Australien)",
                "en": "English (Australia)"
            }
        },
        "en-CA": {
            "fullTag": "en-CA",
            "primaryTag": "en",
            "names": {
                "de": "Englisch (Kanada)",
                "en": "English (Canada)"
            }
        },
        "en-GB": {
            "fullTag": "en-GB",
            "primaryTag": "en",
            "names": {
                "de": "Englisch (Vereinigtes Königreich Großbritannien und Nordirland)",
                "en": "English (United Kingdom of Great Britain and Northern Ireland)"
            }
        },
        "en-IN": {
            "fullTag": "en-IN",
            "primaryTag": "en",
            "names": {
                "de": "Englisch (Indien)",
                "en": "English (India)"
            }
        },
        "en-US": {
            "fullTag": "en-US",
            "primaryTag": "en",
            "names": {
                "de": "Englisch (Vereinigte Staaten)",
                "en": "English (United States of America)"
            }
        },
        "eo": {
            "fullTag": "eo",
            "primaryTag": "eo",
            "names": {
                "de": "Esperanto",
                "en": "Esperanto"
            }
        },
        "es": {
            "fullTag": "es",
            "primaryTag": "es",
            "names": {
                "de": "Spanisch",
                "en": "Spanish"
            }
        },
        "es-ES": {
            "fullTag": "es-ES",
            "primaryTag": "es",
            "names": {
                "de": "Spanisch (Spanien)",
                "en": "Spanish (Spain)"
            }
        },
        "es-MX": {
            "fullTag": "es-MX",
            "primaryTag": "es",
            "names": {
                "de": "Spanisch (Mexiko)",
                "en": "Spanish (Mexico)"
            }
        },
        "es-US": {
            "fullTag": "es-US",
            "primaryTag": "es",
            "names": {
                "de": "Spanisch (Vereinigte Staaten)",
                "en": "Spanish (United States of America)"
            }
        },
        "et": {
            "fullTag": "et",
            "primaryTag": "et",
            "names": {
                "de": "Estnisch",
                "en": "Estonian"
            }
        },
        "eu": {
            "fullTag": "eu",
            "primaryTag": "eu",
            "names": {
                "de": "Baskisch",
                "en": "Basque"
            }
        },
        "eu-ES": {
            "fullTag": "eu-ES",
            "primaryTag": "eu",
            "names": {
                "de": "Baskisch (Spanien)",
                "en": "Basque (Spain)"
            }
        },
        "fa": {
            "fullTag": "fa",
            "primaryTag": "fa",
            "names": {
                "de": "Persisch",
                "en": "Farsi"
            }
        },
        "fa-AF": {
            "fullTag": "fa-AF",
            "primaryTag": "fa",
            "names": {
                "de": "Persisch (Afghanistan)",
                "en": "Farsi (Afghanistan)"
            }
        },
        "fi": {
            "fullTag": "fi",
            "primaryTag": "fi",
            "names": {
                "de": "Finnisch",
                "en": "Finnish"
            }
        },
        "fi-FI": {
            "fullTag": "fi-FI",
            "primaryTag": "fi",
            "names": {
                "de": "Finnisch (Finnland)",
                "en": "Finnish (Finland)"
            }
        },
        "fil": {
            "fullTag": "fil",
            "primaryTag": "fil",
            "names": {
                "de": "Filipino",
                "en": "Filipino"
            }
        },
        "fil-PH": {
            "fullTag": "fil-PH",
            "primaryTag": "fil",
            "names": {
                "de": "Filipino (Philippinen)",
                "en": "Filipino (Philippines)"
            }
        },
        "fr": {
            "fullTag": "fr",
            "primaryTag": "fr",
            "names": {
                "de": "Französisch",
                "en": "French"
            }
        },
        "fr-CA": {
            "fullTag": "fr-CA",
            "primaryTag": "fr",
            "names": {
                "de": "Französisch (Kanada)",
                "en": "French (Canada)"
            }
        },
        "fr-FR": {
            "fullTag": "fr-FR",
            "primaryTag": "fr",
            "names": {
                "de": "Französisch (Frankreich)",
                "en": "French (France)"
            }
        },
        "ga": {
            "fullTag": "ga",
            "primaryTag": "ga",
            "names": {
                "de": "Irisch",
                "en": "Irish"
            }
        },
        "gd": {
            "fullTag": "gd",
            "primaryTag": "gd",
            "names": {
                "de": "Gälisch (Schottland)",
                "en": "Scottish Gaelic"
            }
        },
        "gl": {
            "fullTag": "gl",
            "primaryTag": "gl",
            "names": {
                "de": "Galicisch",
                "en": "Galician"
            }
        },
        "gl-ES": {
            "fullTag": "gl-ES",
            "primaryTag": "gl",
            "names": {
                "de": "Galicisch (Spanien)",
                "en": "Galician (Spain)"
            }
        },
        "gon": {
            "fullTag": "gon",
            "primaryTag": "gon",
            "names": {
                "de": "Gondi",
                "en": "Gondi"
            }
        },
        "gu": {
            "fullTag": "gu",
            "primaryTag": "gu",
            "names": {
                "de": "Gujarati",
                "en": "Gujarati"
            }
        },
        "gu-IN": {
            "fullTag": "gu-IN",
            "primaryTag": "gu",
            "names": {
                "de": "Gujarati (Indien)",
                "en": "Gujarati (India)"
            }
        },
        "ha": {
            "fullTag": "ha",
            "primaryTag": "ha",
            "names": {
                "de": "Hausa",
                "en": "Haussa"
            }
        },
        "haw": {
            "fullTag": "haw",
            "primaryTag": "haw",
            "names": {
                "de": "Hawaiisch",
                "en": "Hawaiian"
            }
        },
        "hbo": {
            "fullTag": "hbo",
            "primaryTag": "hbo",
            "names": {
                "de": "hbo",
                "en": "hbo"
            }
        },
        "he": {
            "fullTag": "he",
            "primaryTag": "he",
            "names": {
                "de": "Hebräisch",
                "en": "Hebrew"
            }
        },
        "he-IL": {
            "fullTag": "he-IL",
            "primaryTag": "he",
            "names": {
                "de": "Hebräisch (Israel)",
                "en": "Hebrew (Israel)"
            }
        },
        "hi": {
            "fullTag": "hi",
            "primaryTag": "hi",
            "names": {
                "de": "Hindi",
                "en": "Hindi"
            }
        },
        "hi-IN": {
            "fullTag": "hi-IN",
            "primaryTag": "hi",
            "names": {
                "de": "Hindi (Indien)",
                "en": "Hindi (India)"
            }
        },
        "hr": {
            "fullTag": "hr",
            "primaryTag": "hr",
            "names": {
                "de": "Kroatisch",
                "en": "Croatian"
            }
        },
        "ht": {
            "fullTag": "ht",
            "primaryTag": "ht",
            "names": {
                "de": "Haiti-Kreolisch",
                "en": "Haitian Creole"
            }
        },
        "hu": {
            "fullTag": "hu",
            "primaryTag": "hu",
            "names": {
                "de": "Ungarisch",
                "en": "Hungarian"
            }
        },
        "hu-HU": {
            "fullTag": "hu-HU",
            "primaryTag": "hu",
            "names": {
                "de": "Ungarisch (Ungarn)",
                "en": "Hungarian (Hungary)"
            }
        },
        "hy": {
            "fullTag": "hy",
            "primaryTag": "hy",
            "names": {
                "de": "Armenisch",
                "en": "Armenian"
            }
        },
        "id": {
            "fullTag": "id",
            "primaryTag": "id",
            "names": {
                "de": "Indonesisch",
                "en": "Indonesian"
            }
        },
        "id-ID": {
            "fullTag": "id-ID",
            "primaryTag": "id",
            "names": {
                "de": "Indonesisch (Indonesien)",
                "en": "Indonesian (Indonesia)"
            }
        },
        "ipa": {
            "fullTag": "ipa",
            "primaryTag": "ipa",
            "names": {
                "de": "ipa",
                "en": "ipa"
            }
        },
        "is": {
            "fullTag": "is",
            "primaryTag": "is",
            "names": {
                "de": "Isländisch",
                "en": "Icelandic"
            }
        },
        "is-IS": {
            "fullTag": "is-IS",
            "primaryTag": "is",
            "names": {
                "de": "Isländisch (Island)",
                "en": "Icelandic (Iceland)"
            }
        },
        "it": {
            "fullTag": "it",
            "primaryTag": "it",
            "names": {
                "de": "Italienisch",
                "en": "Italian"
            }
        },
        "it-IT": {
            "fullTag": "it-IT",
            "primaryTag": "it",
            "names": {
                "de": "Italienisch (Italien)",
                "en": "Italian (Italy)"
            }
        },
        "iu": {
            "fullTag": "iu",
            "primaryTag": "iu",
            "names": {
                "de": "Inuktitut",
                "en": "Inuktitut"
            }
        },
        "ja": {
            "fullTag": "ja",
            "primaryTag": "ja",
            "names": {
                "de": "Japanisch",
                "en": "Japanese"
            }
        },
        "ja-JP": {
            "fullTag": "ja-JP",
            "primaryTag": "ja",
            "names": {
                "de": "Japanisch (Japan)",
                "en": "Japanese (Japan)"
            }
        },
        "ka": {
            "fullTag": "ka",
            "primaryTag": "ka",
            "names": {
                "de": "Georgisch",
                "en": "Georgian"
            }
        },
        "kh": {
            "fullTag": "kh",
            "primaryTag": "kh",
            "names": {
                "de": "kh",
                "en": "kh"
            }
        },
        "kk": {
            "fullTag": "kk",
            "primaryTag": "kk",
            "names": {
                "de": "Kasachisch",
                "en": "Kazakh"
            }
        },
        "km": {
            "fullTag": "km",
            "primaryTag": "km",
            "names": {
                "de": "Khmer",
                "en": "Khmer"
            }
        },
        "kn": {
            "fullTag": "kn",
            "primaryTag": "kn",
            "names": {
                "de": "Kannada",
                "en": "Kannada"
            }
        },
        "kn-IN": {
            "fullTag": "kn-IN",
            "primaryTag": "kn",
            "names": {
                "de": "Kannada (Indien)",
                "en": "Kannada (India)"
            }
        },
        "ko": {
            "fullTag": "ko",
            "primaryTag": "ko",
            "names": {
                "de": "Koreanisch",
                "en": "Korean"
            }
        },
        "ko-KR": {
            "fullTag": "ko-KR",
            "primaryTag": "ko",
            "names": {
                "de": "Koreanisch (Republik Korea)",
                "en": "Korean (Korea, Republic of)"
            }
        },
        "kok": {
            "fullTag": "kok",
            "primaryTag": "kok",
            "names": {
                "de": "Konkani",
                "en": "Konkani"
            }
        },
        "kru": {
            "fullTag": "kru",
            "primaryTag": "kru",
            "names": {
                "de": "Oraon",
                "en": "Kurukh"
            }
        },
        "ks": {
            "fullTag": "ks",
            "primaryTag": "ks",
            "names": {
                "de": "Kaschmiri",
                "en": "Kashmiri"
            }
        },
        "lg": {
            "fullTag": "lg",
            "primaryTag": "lg",
            "names": {
                "de": "Ganda",
                "en": "Ganda"
            }
        },
        "lo": {
            "fullTag": "lo",
            "primaryTag": "lo",
            "names": {
                "de": "Laotisch",
                "en": "Lao"
            }
        },
        "lt": {
            "fullTag": "lt",
            "primaryTag": "lt",
            "names": {
                "de": "Litauisch",
                "en": "Lithuanian"
            }
        },
        "lt-LT": {
            "fullTag": "lt-LT",
            "primaryTag": "lt",
            "names": {
                "de": "Litauisch (Litauen)",
                "en": "Lithuanian (Lithuania)"
            }
        },
        "lv": {
            "fullTag": "lv",
            "primaryTag": "lv",
            "names": {
                "de": "Lettisch",
                "en": "Latvian"
            }
        },
        "lv-LV": {
            "fullTag": "lv-LV",
            "primaryTag": "lv",
            "names": {
                "de": "Lettisch (Lettland)",
                "en": "Latvian (Latvia)"
            }
        },
        "mi": {
            "fullTag": "mi",
            "primaryTag": "mi",
            "names": {
                "de": "Maori",
                "en": "Maori"
            }
        },
        "mk": {
            "fullTag": "mk",
            "primaryTag": "mk",
            "names": {
                "de": "Makedonisch",
                "en": "Macedonian"
            }
        },
        "ml": {
            "fullTag": "ml",
            "primaryTag": "ml",
            "names": {
                "de": "Malayalam",
                "en": "Malayalam"
            }
        },
        "ml-IN": {
            "fullTag": "ml-IN",
            "primaryTag": "ml",
            "names": {
                "de": "Malayalam (Indien)",
                "en": "Malayalam (India)"
            }
        },
        "mn": {
            "fullTag": "mn",
            "primaryTag": "mn",
            "names": {
                "de": "Mongoloisch",
                "en": "Mongolian"
            }
        },
        "mr": {
            "fullTag": "mr",
            "primaryTag": "mr",
            "names": {
                "de": "Marathi",
                "en": "Marathi"
            }
        },
        "mr-IN": {
            "fullTag": "mr-IN",
            "primaryTag": "mr",
            "names": {
                "de": "Marathi (Indien)",
                "en": "Marathi (India)"
            }
        },
        "ms": {
            "fullTag": "ms",
            "primaryTag": "ms",
            "names": {
                "de": "Malaiisch",
                "en": "Malay"
            }
        },
        "ms-MY": {
            "fullTag": "ms-MY",
            "primaryTag": "ms",
            "names": {
                "de": "Malaiisch (Malaysia)",
                "en": "Malay (Malaysia)"
            }
        },
        "mt": {
            "fullTag": "mt",
            "primaryTag": "mt",
            "names": {
                "de": "Maltesisch",
                "en": "Maltese"
            }
        },
        "mul-IL": {
            "fullTag": "mul-IL",
            "primaryTag": "mul",
            "names": {
                "de": "Mehrsprachig (Israel)",
                "en": "Multiple languages (Israel)"
            }
        },
        "mun": {
            "fullTag": "mun",
            "primaryTag": "mun",
            "names": {
                "de": "Munda",
                "en": "Munda"
            }
        },
        "mwr": {
            "fullTag": "mwr",
            "primaryTag": "mwr",
            "names": {
                "de": "Marwari",
                "en": "Marwari"
            }
        },
        "my": {
            "fullTag": "my",
            "primaryTag": "my",
            "names": {
                "de": "Birmanisch",
                "en": "Burmese"
            }
        },
        "nb": {
            "fullTag": "nb",
            "primaryTag": "nb",
            "names": {
                "de": "Norwegisch (Bokmål)",
                "en": "Norwegian (Bokmal)"
            }
        },
        "nb-NO": {
            "fullTag": "nb-NO",
            "primaryTag": "nb",
            "names": {
                "de": "Norwegisch (Bokmål) (Norwegen)",
                "en": "Norwegian (Bokmal) (Norway)"
            }
        },
        "ne": {
            "fullTag": "ne",
            "primaryTag": "ne",
            "names": {
                "de": "Nepalesisch",
                "en": "Nepali"
            }
        },
        "nl": {
            "fullTag": "nl",
            "primaryTag": "nl",
            "names": {
                "de": "Niederländisch",
                "en": "Dutch"
            }
        },
        "nl-BE": {
            "fullTag": "nl-BE",
            "primaryTag": "nl",
            "names": {
                "de": "Niederländisch (Belgien)",
                "en": "Dutch (Belgium)"
            }
        },
        "nl-NL": {
            "fullTag": "nl-NL",
            "primaryTag": "nl",
            "names": {
                "de": "Niederländisch (Niederlande)",
                "en": "Dutch (Netherlands)"
            }
        },
        "nn": {
            "fullTag": "nn",
            "primaryTag": "nn",
            "names": {
                "de": "Norwegisch (Nynorsk)",
                "en": "Norwegian (Nynorsk)"
            }
        },
        "np": {
            "fullTag": "np",
            "primaryTag": "np",
            "names": {
                "de": "np",
                "en": "np"
            }
        },
        "nso": {
            "fullTag": "nso",
            "primaryTag": "nso",
            "names": {
                "de": "Nord-Sotho",
                "en": "Northern Sotho"
            }
        },
        "or": {
            "fullTag": "or",
            "primaryTag": "or",
            "names": {
                "de": "Oriya",
                "en": "Odia"
            }
        },
        "pa": {
            "fullTag": "pa",
            "primaryTag": "pa",
            "names": {
                "de": "Punjabi",
                "en": "Punjabi"
            }
        },
        "pa-IN": {
            "fullTag": "pa-IN",
            "primaryTag": "pa",
            "names": {
                "de": "Punjabi (Indien)",
                "en": "Punjabi (India)"
            }
        },
        "pi": {
            "fullTag": "pi",
            "primaryTag": "pi",
            "names": {
                "de": "Pali",
                "en": "Pali"
            }
        },
        "pl": {
            "fullTag": "pl",
            "primaryTag": "pl",
            "names": {
                "de": "Polnisch",
                "en": "Polish"
            }
        },
        "pl-PL": {
            "fullTag": "pl-PL",
            "primaryTag": "pl",
            "names": {
                "de": "Polnisch (Polen)",
                "en": "Polish (Poland)"
            }
        },
        "ps": {
            "fullTag": "ps",
            "primaryTag": "ps",
            "names": {
                "de": "Pashtu",
                "en": "Pashto"
            }
        },
        "pt": {
            "fullTag": "pt",
            "primaryTag": "pt",
            "names": {
                "de": "Portugiesisch",
                "en": "Portuguese"
            }
        },
        "pt-BR": {
            "fullTag": "pt-BR",
            "primaryTag": "pt",
            "names": {
                "de": "Portugiesisch (Brasilien)",
                "en": "Portuguese (Brazil)"
            }
        },
        "pt-PT": {
            "fullTag": "pt-PT",
            "primaryTag": "pt",
            "names": {
                "de": "Portugiesisch (Portugal)",
                "en": "Portuguese (Portugal)"
            }
        },
        "pu": {
            "fullTag": "pu",
            "primaryTag": "pu",
            "names": {
                "de": "pu",
                "en": "pu"
            }
        },
        "ro": {
            "fullTag": "ro",
            "primaryTag": "ro",
            "names": {
                "de": "Rumänisch",
                "en": "Romanian"
            }
        },
        "ro-RO": {
            "fullTag": "ro-RO",
            "primaryTag": "ro",
            "names": {
                "de": "Rumänisch (Rumänien)",
                "en": "Romanian (Romania)"
            }
        },
        "ru": {
            "fullTag": "ru",
            "primaryTag": "ru",
            "names": {
                "de": "Russisch",
                "en": "Russian"
            }
        },
        "ru-RU": {
            "fullTag": "ru-RU",
            "primaryTag": "ru",
            "names": {
                "de": "Russisch (Russland)",
                "en": "Russian (Russia)"
            }
        },
        "rw": {
            "fullTag": "rw",
            "primaryTag": "rw",
            "names": {
                "de": "Kinyarwanda",
                "en": "Kinyarwanda"
            }
        },
        "sa": {
            "fullTag": "sa",
            "primaryTag": "sa",
            "names": {
                "de": "Sanskrit",
                "en": "Sanskrit"
            }
        },
        "sah": {
            "fullTag": "sah",
            "primaryTag": "sah",
            "names": {
                "de": "Jakutisch",
                "en": "Sakha"
            }
        },
        "se": {
            "fullTag": "se",
            "primaryTag": "se",
            "names": {
                "de": "Samisch",
                "en": "Sami"
            }
        },
        "si": {
            "fullTag": "si",
            "primaryTag": "si",
            "names": {
                "de": "Singhalesisch",
                "en": "Sinhala"
            }
        },
        "sin": {
            "fullTag": "sin",
            "primaryTag": "sin",
            "names": {
                "de": "Singhalesisch",
                "en": "Sinhala"
            }
        },
        "sk": {
            "fullTag": "sk",
            "primaryTag": "sk",
            "names": {
                "de": "Slowakisch",
                "en": "Slovak"
            }
        },
        "sk-SK": {
            "fullTag": "sk-SK",
            "primaryTag": "sk",
            "names": {
                "de": "Slowakisch (Slowakei)",
                "en": "Slovak (Slovakia)"
            }
        },
        "sl": {
            "fullTag": "sl",
            "primaryTag": "sl",
            "names": {
                "de": "Slovenisch",
                "en": "Slovenian"
            }
        },
        "so": {
            "fullTag": "so",
            "primaryTag": "so",
            "names": {
                "de": "Somali",
                "en": "Somali"
            }
        },
        "sq": {
            "fullTag": "sq",
            "primaryTag": "sq",
            "names": {
                "de": "Albanian",
                "en": "Albanian"
            }
        },
        "sr": {
            "fullTag": "sr",
            "primaryTag": "sr",
            "names": {
                "de": "Serbisch",
                "en": "Serbian"
            }
        },
        "sr-RS": {
            "fullTag": "sr-RS",
            "primaryTag": "sr",
            "names": {
                "de": "Serbisch (Serbien)",
                "en": "Serbian (Serbia)"
            }
        },
        "st": {
            "fullTag": "st",
            "primaryTag": "st",
            "names": {
                "de": "Süd-Sotho",
                "en": "Southern Sotho"
            }
        },
        "sv": {
            "fullTag": "sv",
            "primaryTag": "sv",
            "names": {
                "de": "Schwedisch",
                "en": "Swedish"
            }
        },
        "sv-SE": {
            "fullTag": "sv-SE",
            "primaryTag": "sv",
            "names": {
                "de": "Schwedisch (Schweden)",
                "en": "Swedish (Sweden)"
            }
        },
        "sw": {
            "fullTag": "sw",
            "primaryTag": "sw",
            "names": {
                "de": "Swahili",
                "en": "Swahili"
            }
        },
        "syr": {
            "fullTag": "syr",
            "primaryTag": "syr",
            "names": {
                "de": "Syrisch",
                "en": "Syriac"
            }
        },
        "ta": {
            "fullTag": "ta",
            "primaryTag": "ta",
            "names": {
                "de": "Tamilisch",
                "en": "Tamil"
            }
        },
        "ta-IN": {
            "fullTag": "ta-IN",
            "primaryTag": "ta",
            "names": {
                "de": "Tamilisch (Indien)",
                "en": "Tamil (India)"
            }
        },
        "te": {
            "fullTag": "te",
            "primaryTag": "te",
            "names": {
                "de": "Telugu",
                "en": "Telugu"
            }
        },
        "te-IN": {
            "fullTag": "te-IN",
            "primaryTag": "te",
            "names": {
                "de": "Telugu (Indien)",
                "en": "Telugu (India)"
            }
        },
        "th": {
            "fullTag": "th",
            "primaryTag": "th",
            "names": {
                "de": "Thailändisch",
                "en": "Thai"
            }
        },
        "th-TH": {
            "fullTag": "th-TH",
            "primaryTag": "th",
            "names": {
                "de": "Thailändisch (Thailand)",
                "en": "Thai (Thailand)"
            }
        },
        "tl": {
            "fullTag": "tl",
            "primaryTag": "tl",
            "names": {
                "de": "Tagalog",
                "en": "Tagalog"
            }
        },
        "tlg": {
            "fullTag": "tlg",
            "primaryTag": "tlg",
            "names": {
                "de": "tlg",
                "en": "tlg"
            }
        },
        "tn": {
            "fullTag": "tn",
            "primaryTag": "tn",
            "names": {
                "de": "Tswana",
                "en": "Tswana"
            }
        },
        "tr": {
            "fullTag": "tr",
            "primaryTag": "tr",
            "names": {
                "de": "Türkisch",
                "en": "Turkish"
            }
        },
        "tr-TR": {
            "fullTag": "tr-TR",
            "primaryTag": "tr",
            "names": {
                "de": "Türkisch (Türkei)",
                "en": "Turkish (Turkey)"
            }
        },
        "tt": {
            "fullTag": "tt",
            "primaryTag": "tt",
            "names": {
                "de": "Tatarisch",
                "en": "Tatar"
            }
        },
        "uga": {
            "fullTag": "uga",
            "primaryTag": "uga",
            "names": {
                "de": "Ugaritisch",
                "en": "Ugaritic"
            }
        },
        "uk": {
            "fullTag": "uk",
            "primaryTag": "uk",
            "names": {
                "de": "Ukrainisch",
                "en": "Ukrainian"
            }
        },
        "uk-UA": {
            "fullTag": "uk-UA",
            "primaryTag": "uk",
            "names": {
                "de": "Ukrainisch (Ukraine)",
                "en": "Ukrainian (Ukraine)"
            }
        },
        "ur": {
            "fullTag": "ur",
            "primaryTag": "ur",
            "names": {
                "de": "Urdu",
                "en": "Urdu"
            }
        },
        "uz": {
            "fullTag": "uz",
            "primaryTag": "uz",
            "names": {
                "de": "Usbekisch",
                "en": "Uzbek"
            }
        },
        "ve": {
            "fullTag": "ve",
            "primaryTag": "ve",
            "names": {
                "de": "Venda",
                "en": "Venda"
            }
        },
        "vi": {
            "fullTag": "vi",
            "primaryTag": "vi",
            "names": {
                "de": "Vietnamesisch",
                "en": "Vietnamese"
            }
        },
        "vi-VN": {
            "fullTag": "vi-VN",
            "primaryTag": "vi",
            "names": {
                "de": "Vietnamesisch (Vietnam)",
                "en": "Vietnamese (Viet Nam)"
            }
        },
        "xh": {
            "fullTag": "xh",
            "primaryTag": "xh",
            "names": {
                "de": "Xhosa",
                "en": "Xhosa"
            }
        },
        "xlu": {
            "fullTag": "xlu",
            "primaryTag": "xlu",
            "names": {
                "de": "xlu",
                "en": "xlu"
            }
        },
        "yi": {
            "fullTag": "yi",
            "primaryTag": "yi",
            "names": {
                "de": "Jiddisch",
                "en": "Yiddish"
            }
        },
        "yue": {
            "fullTag": "yue",
            "primaryTag": "yue",
            "names": {
                "de": "Kantonesisch",
                "en": "Cantonese"
            }
        },
        "yue-HK": {
            "fullTag": "yue-HK",
            "primaryTag": "yue",
            "names": {
                "de": "Kantonesisch (Hongkong)",
                "en": "Cantonese (Hong Kong)"
            }
        },
        "zh": {
            "fullTag": "zh",
            "primaryTag": "zh",
            "names": {
                "de": "Chinesisch",
                "en": "Chinese"
            }
        },
        "zh-TW": {
            "fullTag": "zh-TW",
            "primaryTag": "zh",
            "names": {
                "de": "Chinesisch (Taiwan)",
                "en": "Chinese (Taiwan)"
            }
        },
        "zu": {
            "fullTag": "zu",
            "primaryTag": "zu",
            "names": {
                "de": "Zulu",
                "en": "Zulu"
            }
        }
    },
    "services": {
        "Google Cloud Text-to-Speech": {
            "canonicalName": "Google Cloud Text-to-Speech",
            "shortEngineName": "google",
            "names": [
                "Google Cloud Text-to-Speech",
                "google",
                "GOOGLE"
            ],
            "voices": {
                "af-ZA-Standard-A": {
                    "name": "af-ZA-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "af",
                        "af-ZA"
                    ]
                },
                "am-ET-Standard-A": {
                    "name": "am-ET-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "am",
                        "am-ET"
                    ]
                },
                "am-ET-Standard-B": {
                    "name": "am-ET-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "am",
                        "am-ET"
                    ]
                },
                "am-ET-Wavenet-A": {
                    "name": "am-ET-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "am",
                        "am-ET"
                    ]
                },
                "am-ET-Wavenet-B": {
                    "name": "am-ET-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "am",
                        "am-ET"
                    ]
                },
                "ar-XA-Standard-A": {
                    "name": "ar-XA-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "ar-XA-Standard-B": {
                    "name": "ar-XA-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "ar-XA-Standard-C": {
                    "name": "ar-XA-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "ar-XA-Standard-D": {
                    "name": "ar-XA-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "ar-XA-Wavenet-A": {
                    "name": "ar-XA-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "ar-XA-Wavenet-B": {
                    "name": "ar-XA-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "ar-XA-Wavenet-C": {
                    "name": "ar-XA-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "ar-XA-Wavenet-D": {
                    "name": "ar-XA-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "ar",
                        "ar-XA"
                    ]
                },
                "bg-BG-Standard-A": {
                    "name": "bg-BG-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "bg",
                        "bg-BG"
                    ]
                },
                "bn-IN-Standard-A": {
                    "name": "bn-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "bn-IN-Standard-B": {
                    "name": "bn-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "bn-IN-Standard-C": {
                    "name": "bn-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "bn-IN-Standard-D": {
                    "name": "bn-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "bn-IN-Wavenet-A": {
                    "name": "bn-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "bn-IN-Wavenet-B": {
                    "name": "bn-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "bn-IN-Wavenet-C": {
                    "name": "bn-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "bn-IN-Wavenet-D": {
                    "name": "bn-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "bn",
                        "bn-IN"
                    ]
                },
                "ca-ES-Standard-A": {
                    "name": "ca-ES-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ca",
                        "ca-ES"
                    ]
                },
                "cmn-CN-Standard-A": {
                    "name": "cmn-CN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-CN-Standard-B": {
                    "name": "cmn-CN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-CN-Standard-C": {
                    "name": "cmn-CN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-CN-Standard-D": {
                    "name": "cmn-CN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-CN-Wavenet-A": {
                    "name": "cmn-CN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-CN-Wavenet-B": {
                    "name": "cmn-CN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-CN-Wavenet-C": {
                    "name": "cmn-CN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-CN-Wavenet-D": {
                    "name": "cmn-CN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "cmn",
                        "cmn-CN"
                    ]
                },
                "cmn-TW-Standard-A": {
                    "name": "cmn-TW-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "cmn",
                        "cmn-TW"
                    ]
                },
                "cmn-TW-Standard-B": {
                    "name": "cmn-TW-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "cmn",
                        "cmn-TW"
                    ]
                },
                "cmn-TW-Standard-C": {
                    "name": "cmn-TW-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "cmn",
                        "cmn-TW"
                    ]
                },
                "cmn-TW-Wavenet-A": {
                    "name": "cmn-TW-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "cmn",
                        "cmn-TW"
                    ]
                },
                "cmn-TW-Wavenet-B": {
                    "name": "cmn-TW-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "cmn",
                        "cmn-TW"
                    ]
                },
                "cmn-TW-Wavenet-C": {
                    "name": "cmn-TW-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "cmn",
                        "cmn-TW"
                    ]
                },
                "cs-CZ-Standard-A": {
                    "name": "cs-CZ-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "cs",
                        "cs-CZ"
                    ]
                },
                "cs-CZ-Wavenet-A": {
                    "name": "cs-CZ-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "cs",
                        "cs-CZ"
                    ]
                },
                "da-DK-Neural2-D": {
                    "name": "da-DK-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Standard-A": {
                    "name": "da-DK-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Standard-C": {
                    "name": "da-DK-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Standard-D": {
                    "name": "da-DK-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Standard-E": {
                    "name": "da-DK-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Wavenet-A": {
                    "name": "da-DK-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Wavenet-C": {
                    "name": "da-DK-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Wavenet-D": {
                    "name": "da-DK-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "da-DK-Wavenet-E": {
                    "name": "da-DK-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "da",
                        "da-DK"
                    ]
                },
                "de-DE-Neural2-A": {
                    "name": "de-DE-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Neural2-B": {
                    "name": "de-DE-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Neural2-C": {
                    "name": "de-DE-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Neural2-D": {
                    "name": "de-DE-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Neural2-F": {
                    "name": "de-DE-Neural2-F",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Polyglot-1": {
                    "name": "de-DE-Polyglot-1",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Standard-A": {
                    "name": "de-DE-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Standard-B": {
                    "name": "de-DE-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Standard-C": {
                    "name": "de-DE-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Standard-D": {
                    "name": "de-DE-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Standard-E": {
                    "name": "de-DE-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Standard-F": {
                    "name": "de-DE-Standard-F",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Studio-B": {
                    "name": "de-DE-Studio-B",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Studio-C": {
                    "name": "de-DE-Studio-C",
                    "costMultiplier": 1,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Wavenet-A": {
                    "name": "de-DE-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Wavenet-B": {
                    "name": "de-DE-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Wavenet-C": {
                    "name": "de-DE-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Wavenet-D": {
                    "name": "de-DE-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Wavenet-E": {
                    "name": "de-DE-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "de-DE-Wavenet-F": {
                    "name": "de-DE-Wavenet-F",
                    "costMultiplier": 4,
                    "languages": [
                        "de",
                        "de-DE"
                    ]
                },
                "el-GR-Standard-A": {
                    "name": "el-GR-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "el",
                        "el-GR"
                    ]
                },
                "el-GR-Wavenet-A": {
                    "name": "el-GR-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "el",
                        "el-GR"
                    ]
                },
                "en-AU-Neural2-A": {
                    "name": "en-AU-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Neural2-B": {
                    "name": "en-AU-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Neural2-C": {
                    "name": "en-AU-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Neural2-D": {
                    "name": "en-AU-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-News-E": {
                    "name": "en-AU-News-E",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-News-F": {
                    "name": "en-AU-News-F",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-News-G": {
                    "name": "en-AU-News-G",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Polyglot-1": {
                    "name": "en-AU-Polyglot-1",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Standard-A": {
                    "name": "en-AU-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Standard-B": {
                    "name": "en-AU-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Standard-C": {
                    "name": "en-AU-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Standard-D": {
                    "name": "en-AU-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Wavenet-A": {
                    "name": "en-AU-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Wavenet-B": {
                    "name": "en-AU-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Wavenet-C": {
                    "name": "en-AU-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-AU-Wavenet-D": {
                    "name": "en-AU-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-AU"
                    ]
                },
                "en-GB-Neural2-A": {
                    "name": "en-GB-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Neural2-B": {
                    "name": "en-GB-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Neural2-C": {
                    "name": "en-GB-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Neural2-D": {
                    "name": "en-GB-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Neural2-F": {
                    "name": "en-GB-Neural2-F",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-News-G": {
                    "name": "en-GB-News-G",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-News-H": {
                    "name": "en-GB-News-H",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-News-I": {
                    "name": "en-GB-News-I",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-News-J": {
                    "name": "en-GB-News-J",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-News-K": {
                    "name": "en-GB-News-K",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-News-L": {
                    "name": "en-GB-News-L",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-News-M": {
                    "name": "en-GB-News-M",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Standard-A": {
                    "name": "en-GB-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Standard-B": {
                    "name": "en-GB-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Standard-C": {
                    "name": "en-GB-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Standard-D": {
                    "name": "en-GB-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Standard-F": {
                    "name": "en-GB-Standard-F",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Studio-B": {
                    "name": "en-GB-Studio-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Studio-C": {
                    "name": "en-GB-Studio-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Wavenet-A": {
                    "name": "en-GB-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Wavenet-B": {
                    "name": "en-GB-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Wavenet-C": {
                    "name": "en-GB-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Wavenet-D": {
                    "name": "en-GB-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-GB-Wavenet-F": {
                    "name": "en-GB-Wavenet-F",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-GB"
                    ]
                },
                "en-IN-Neural2-A": {
                    "name": "en-IN-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Neural2-B": {
                    "name": "en-IN-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Neural2-C": {
                    "name": "en-IN-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Neural2-D": {
                    "name": "en-IN-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Standard-A": {
                    "name": "en-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Standard-B": {
                    "name": "en-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Standard-C": {
                    "name": "en-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Standard-D": {
                    "name": "en-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Wavenet-A": {
                    "name": "en-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Wavenet-B": {
                    "name": "en-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Wavenet-C": {
                    "name": "en-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-IN-Wavenet-D": {
                    "name": "en-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-IN"
                    ]
                },
                "en-US-Casual-K": {
                    "name": "en-US-Casual-K",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Journey-D": {
                    "name": "en-US-Journey-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Journey-F": {
                    "name": "en-US-Journey-F",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-A": {
                    "name": "en-US-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-C": {
                    "name": "en-US-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-D": {
                    "name": "en-US-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-E": {
                    "name": "en-US-Neural2-E",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-F": {
                    "name": "en-US-Neural2-F",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-G": {
                    "name": "en-US-Neural2-G",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-H": {
                    "name": "en-US-Neural2-H",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-I": {
                    "name": "en-US-Neural2-I",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Neural2-J": {
                    "name": "en-US-Neural2-J",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-News-K": {
                    "name": "en-US-News-K",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-News-L": {
                    "name": "en-US-News-L",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-News-N": {
                    "name": "en-US-News-N",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Polyglot-1": {
                    "name": "en-US-Polyglot-1",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-A": {
                    "name": "en-US-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-B": {
                    "name": "en-US-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-C": {
                    "name": "en-US-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-D": {
                    "name": "en-US-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-E": {
                    "name": "en-US-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-F": {
                    "name": "en-US-Standard-F",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-G": {
                    "name": "en-US-Standard-G",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-H": {
                    "name": "en-US-Standard-H",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-I": {
                    "name": "en-US-Standard-I",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Standard-J": {
                    "name": "en-US-Standard-J",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Studio-O": {
                    "name": "en-US-Studio-O",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Studio-Q": {
                    "name": "en-US-Studio-Q",
                    "costMultiplier": 1,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-A": {
                    "name": "en-US-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-B": {
                    "name": "en-US-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-C": {
                    "name": "en-US-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-D": {
                    "name": "en-US-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-E": {
                    "name": "en-US-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-F": {
                    "name": "en-US-Wavenet-F",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-G": {
                    "name": "en-US-Wavenet-G",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-H": {
                    "name": "en-US-Wavenet-H",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-I": {
                    "name": "en-US-Wavenet-I",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "en-US-Wavenet-J": {
                    "name": "en-US-Wavenet-J",
                    "costMultiplier": 4,
                    "languages": [
                        "en",
                        "en-US"
                    ]
                },
                "es-ES-Neural2-A": {
                    "name": "es-ES-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Neural2-B": {
                    "name": "es-ES-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Neural2-C": {
                    "name": "es-ES-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Neural2-D": {
                    "name": "es-ES-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Neural2-E": {
                    "name": "es-ES-Neural2-E",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Neural2-F": {
                    "name": "es-ES-Neural2-F",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Polyglot-1": {
                    "name": "es-ES-Polyglot-1",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Standard-A": {
                    "name": "es-ES-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Standard-B": {
                    "name": "es-ES-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Standard-C": {
                    "name": "es-ES-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Standard-D": {
                    "name": "es-ES-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Wavenet-B": {
                    "name": "es-ES-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Wavenet-C": {
                    "name": "es-ES-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-ES-Wavenet-D": {
                    "name": "es-ES-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "es",
                        "es-ES"
                    ]
                },
                "es-US-Neural2-A": {
                    "name": "es-US-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Neural2-B": {
                    "name": "es-US-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Neural2-C": {
                    "name": "es-US-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-News-D": {
                    "name": "es-US-News-D",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-News-E": {
                    "name": "es-US-News-E",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-News-F": {
                    "name": "es-US-News-F",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-News-G": {
                    "name": "es-US-News-G",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Polyglot-1": {
                    "name": "es-US-Polyglot-1",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Standard-A": {
                    "name": "es-US-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Standard-B": {
                    "name": "es-US-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Standard-C": {
                    "name": "es-US-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Studio-B": {
                    "name": "es-US-Studio-B",
                    "costMultiplier": 1,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Wavenet-A": {
                    "name": "es-US-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Wavenet-B": {
                    "name": "es-US-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "es-US-Wavenet-C": {
                    "name": "es-US-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "es",
                        "es-US"
                    ]
                },
                "eu-ES-Standard-A": {
                    "name": "eu-ES-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "eu",
                        "eu-ES"
                    ]
                },
                "fi-FI-Standard-A": {
                    "name": "fi-FI-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fi",
                        "fi-FI"
                    ]
                },
                "fi-FI-Wavenet-A": {
                    "name": "fi-FI-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "fi",
                        "fi-FI"
                    ]
                },
                "fil-PH-Standard-A": {
                    "name": "fil-PH-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-PH-Standard-B": {
                    "name": "fil-PH-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-PH-Standard-C": {
                    "name": "fil-PH-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-PH-Standard-D": {
                    "name": "fil-PH-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-PH-Wavenet-A": {
                    "name": "fil-PH-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-PH-Wavenet-B": {
                    "name": "fil-PH-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-PH-Wavenet-C": {
                    "name": "fil-PH-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-PH-Wavenet-D": {
                    "name": "fil-PH-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-ph-Neural2-A": {
                    "name": "fil-ph-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fil-ph-Neural2-D": {
                    "name": "fil-ph-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "fil",
                        "fil-PH"
                    ]
                },
                "fr-CA-Neural2-A": {
                    "name": "fr-CA-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Neural2-B": {
                    "name": "fr-CA-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Neural2-C": {
                    "name": "fr-CA-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Neural2-D": {
                    "name": "fr-CA-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Standard-A": {
                    "name": "fr-CA-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Standard-B": {
                    "name": "fr-CA-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Standard-C": {
                    "name": "fr-CA-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Standard-D": {
                    "name": "fr-CA-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Wavenet-A": {
                    "name": "fr-CA-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Wavenet-B": {
                    "name": "fr-CA-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Wavenet-C": {
                    "name": "fr-CA-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-CA-Wavenet-D": {
                    "name": "fr-CA-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-CA"
                    ]
                },
                "fr-FR-Neural2-A": {
                    "name": "fr-FR-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Neural2-B": {
                    "name": "fr-FR-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Neural2-C": {
                    "name": "fr-FR-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Neural2-D": {
                    "name": "fr-FR-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Neural2-E": {
                    "name": "fr-FR-Neural2-E",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Polyglot-1": {
                    "name": "fr-FR-Polyglot-1",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Standard-A": {
                    "name": "fr-FR-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Standard-B": {
                    "name": "fr-FR-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Standard-C": {
                    "name": "fr-FR-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Standard-D": {
                    "name": "fr-FR-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Standard-E": {
                    "name": "fr-FR-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Studio-A": {
                    "name": "fr-FR-Studio-A",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Studio-D": {
                    "name": "fr-FR-Studio-D",
                    "costMultiplier": 1,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Wavenet-A": {
                    "name": "fr-FR-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Wavenet-B": {
                    "name": "fr-FR-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Wavenet-C": {
                    "name": "fr-FR-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Wavenet-D": {
                    "name": "fr-FR-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "fr-FR-Wavenet-E": {
                    "name": "fr-FR-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "fr",
                        "fr-FR"
                    ]
                },
                "gl-ES-Standard-A": {
                    "name": "gl-ES-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "gl",
                        "gl-ES"
                    ]
                },
                "gu-IN-Standard-A": {
                    "name": "gu-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "gu-IN-Standard-B": {
                    "name": "gu-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "gu-IN-Standard-C": {
                    "name": "gu-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "gu-IN-Standard-D": {
                    "name": "gu-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "gu-IN-Wavenet-A": {
                    "name": "gu-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "gu-IN-Wavenet-B": {
                    "name": "gu-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "gu-IN-Wavenet-C": {
                    "name": "gu-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "gu-IN-Wavenet-D": {
                    "name": "gu-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "gu",
                        "gu-IN"
                    ]
                },
                "he-IL-Standard-A": {
                    "name": "he-IL-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "he-IL-Standard-B": {
                    "name": "he-IL-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "he-IL-Standard-C": {
                    "name": "he-IL-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "he-IL-Standard-D": {
                    "name": "he-IL-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "he-IL-Wavenet-A": {
                    "name": "he-IL-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "he-IL-Wavenet-B": {
                    "name": "he-IL-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "he-IL-Wavenet-C": {
                    "name": "he-IL-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "he-IL-Wavenet-D": {
                    "name": "he-IL-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "he",
                        "he-IL"
                    ]
                },
                "hi-IN-Neural2-A": {
                    "name": "hi-IN-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Neural2-B": {
                    "name": "hi-IN-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Neural2-C": {
                    "name": "hi-IN-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Neural2-D": {
                    "name": "hi-IN-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Standard-A": {
                    "name": "hi-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Standard-B": {
                    "name": "hi-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Standard-C": {
                    "name": "hi-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Standard-D": {
                    "name": "hi-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Wavenet-A": {
                    "name": "hi-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Wavenet-B": {
                    "name": "hi-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Wavenet-C": {
                    "name": "hi-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hi-IN-Wavenet-D": {
                    "name": "hi-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "hi",
                        "hi-IN"
                    ]
                },
                "hu-HU-Standard-A": {
                    "name": "hu-HU-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "hu",
                        "hu-HU"
                    ]
                },
                "hu-HU-Wavenet-A": {
                    "name": "hu-HU-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "hu",
                        "hu-HU"
                    ]
                },
                "id-ID-Standard-A": {
                    "name": "id-ID-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "id-ID-Standard-B": {
                    "name": "id-ID-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "id-ID-Standard-C": {
                    "name": "id-ID-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "id-ID-Standard-D": {
                    "name": "id-ID-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "id-ID-Wavenet-A": {
                    "name": "id-ID-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "id-ID-Wavenet-B": {
                    "name": "id-ID-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "id-ID-Wavenet-C": {
                    "name": "id-ID-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "id-ID-Wavenet-D": {
                    "name": "id-ID-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "id",
                        "id-ID"
                    ]
                },
                "is-IS-Standard-A": {
                    "name": "is-IS-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "is",
                        "is-IS"
                    ]
                },
                "it-IT-Neural2-A": {
                    "name": "it-IT-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Neural2-C": {
                    "name": "it-IT-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Standard-A": {
                    "name": "it-IT-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Standard-B": {
                    "name": "it-IT-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Standard-C": {
                    "name": "it-IT-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Standard-D": {
                    "name": "it-IT-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Wavenet-A": {
                    "name": "it-IT-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Wavenet-B": {
                    "name": "it-IT-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Wavenet-C": {
                    "name": "it-IT-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "it-IT-Wavenet-D": {
                    "name": "it-IT-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "it",
                        "it-IT"
                    ]
                },
                "ja-JP-Neural2-B": {
                    "name": "ja-JP-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Neural2-C": {
                    "name": "ja-JP-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Neural2-D": {
                    "name": "ja-JP-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Standard-A": {
                    "name": "ja-JP-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Standard-B": {
                    "name": "ja-JP-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Standard-C": {
                    "name": "ja-JP-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Standard-D": {
                    "name": "ja-JP-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Wavenet-A": {
                    "name": "ja-JP-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Wavenet-B": {
                    "name": "ja-JP-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Wavenet-C": {
                    "name": "ja-JP-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "ja-JP-Wavenet-D": {
                    "name": "ja-JP-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "ja",
                        "ja-JP"
                    ]
                },
                "kn-IN-Standard-A": {
                    "name": "kn-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "kn-IN-Standard-B": {
                    "name": "kn-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "kn-IN-Standard-C": {
                    "name": "kn-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "kn-IN-Standard-D": {
                    "name": "kn-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "kn-IN-Wavenet-A": {
                    "name": "kn-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "kn-IN-Wavenet-B": {
                    "name": "kn-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "kn-IN-Wavenet-C": {
                    "name": "kn-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "kn-IN-Wavenet-D": {
                    "name": "kn-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "kn",
                        "kn-IN"
                    ]
                },
                "ko-KR-Neural2-A": {
                    "name": "ko-KR-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Neural2-B": {
                    "name": "ko-KR-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Neural2-C": {
                    "name": "ko-KR-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Standard-A": {
                    "name": "ko-KR-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Standard-B": {
                    "name": "ko-KR-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Standard-C": {
                    "name": "ko-KR-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Standard-D": {
                    "name": "ko-KR-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Wavenet-A": {
                    "name": "ko-KR-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Wavenet-B": {
                    "name": "ko-KR-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Wavenet-C": {
                    "name": "ko-KR-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "ko-KR-Wavenet-D": {
                    "name": "ko-KR-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "ko",
                        "ko-KR"
                    ]
                },
                "lt-LT-Standard-A": {
                    "name": "lt-LT-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "lt",
                        "lt-LT"
                    ]
                },
                "lv-LV-Standard-A": {
                    "name": "lv-LV-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "lv",
                        "lv-LV"
                    ]
                },
                "ml-IN-Standard-A": {
                    "name": "ml-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "ml-IN-Standard-B": {
                    "name": "ml-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "ml-IN-Standard-C": {
                    "name": "ml-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "ml-IN-Standard-D": {
                    "name": "ml-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "ml-IN-Wavenet-A": {
                    "name": "ml-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "ml-IN-Wavenet-B": {
                    "name": "ml-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "ml-IN-Wavenet-C": {
                    "name": "ml-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "ml-IN-Wavenet-D": {
                    "name": "ml-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "ml",
                        "ml-IN"
                    ]
                },
                "mr-IN-Standard-A": {
                    "name": "mr-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "mr",
                        "mr-IN"
                    ]
                },
                "mr-IN-Standard-B": {
                    "name": "mr-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "mr",
                        "mr-IN"
                    ]
                },
                "mr-IN-Standard-C": {
                    "name": "mr-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "mr",
                        "mr-IN"
                    ]
                },
                "mr-IN-Wavenet-A": {
                    "name": "mr-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "mr",
                        "mr-IN"
                    ]
                },
                "mr-IN-Wavenet-B": {
                    "name": "mr-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "mr",
                        "mr-IN"
                    ]
                },
                "mr-IN-Wavenet-C": {
                    "name": "mr-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "mr",
                        "mr-IN"
                    ]
                },
                "ms-MY-Standard-A": {
                    "name": "ms-MY-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "ms-MY-Standard-B": {
                    "name": "ms-MY-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "ms-MY-Standard-C": {
                    "name": "ms-MY-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "ms-MY-Standard-D": {
                    "name": "ms-MY-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "ms-MY-Wavenet-A": {
                    "name": "ms-MY-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "ms-MY-Wavenet-B": {
                    "name": "ms-MY-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "ms-MY-Wavenet-C": {
                    "name": "ms-MY-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "ms-MY-Wavenet-D": {
                    "name": "ms-MY-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "ms",
                        "ms-MY"
                    ]
                },
                "nb-NO-Standard-A": {
                    "name": "nb-NO-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Standard-B": {
                    "name": "nb-NO-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Standard-C": {
                    "name": "nb-NO-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Standard-D": {
                    "name": "nb-NO-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Standard-E": {
                    "name": "nb-NO-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Wavenet-A": {
                    "name": "nb-NO-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Wavenet-B": {
                    "name": "nb-NO-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Wavenet-C": {
                    "name": "nb-NO-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Wavenet-D": {
                    "name": "nb-NO-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nb-NO-Wavenet-E": {
                    "name": "nb-NO-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "nb",
                        "nb-NO"
                    ]
                },
                "nl-BE-Standard-A": {
                    "name": "nl-BE-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "nl",
                        "nl-BE"
                    ]
                },
                "nl-BE-Standard-B": {
                    "name": "nl-BE-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "nl",
                        "nl-BE"
                    ]
                },
                "nl-BE-Wavenet-A": {
                    "name": "nl-BE-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "nl",
                        "nl-BE"
                    ]
                },
                "nl-BE-Wavenet-B": {
                    "name": "nl-BE-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "nl",
                        "nl-BE"
                    ]
                },
                "nl-NL-Standard-A": {
                    "name": "nl-NL-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Standard-B": {
                    "name": "nl-NL-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Standard-C": {
                    "name": "nl-NL-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Standard-D": {
                    "name": "nl-NL-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Standard-E": {
                    "name": "nl-NL-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Wavenet-A": {
                    "name": "nl-NL-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Wavenet-B": {
                    "name": "nl-NL-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Wavenet-C": {
                    "name": "nl-NL-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Wavenet-D": {
                    "name": "nl-NL-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "nl-NL-Wavenet-E": {
                    "name": "nl-NL-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "nl",
                        "nl-NL"
                    ]
                },
                "pa-IN-Standard-A": {
                    "name": "pa-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pa-IN-Standard-B": {
                    "name": "pa-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pa-IN-Standard-C": {
                    "name": "pa-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pa-IN-Standard-D": {
                    "name": "pa-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pa-IN-Wavenet-A": {
                    "name": "pa-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pa-IN-Wavenet-B": {
                    "name": "pa-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pa-IN-Wavenet-C": {
                    "name": "pa-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pa-IN-Wavenet-D": {
                    "name": "pa-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "pa",
                        "pa-IN"
                    ]
                },
                "pl-PL-Standard-A": {
                    "name": "pl-PL-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Standard-B": {
                    "name": "pl-PL-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Standard-C": {
                    "name": "pl-PL-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Standard-D": {
                    "name": "pl-PL-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Standard-E": {
                    "name": "pl-PL-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Wavenet-A": {
                    "name": "pl-PL-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Wavenet-B": {
                    "name": "pl-PL-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Wavenet-C": {
                    "name": "pl-PL-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Wavenet-D": {
                    "name": "pl-PL-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pl-PL-Wavenet-E": {
                    "name": "pl-PL-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "pl",
                        "pl-PL"
                    ]
                },
                "pt-BR-Neural2-A": {
                    "name": "pt-BR-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Neural2-B": {
                    "name": "pt-BR-Neural2-B",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Neural2-C": {
                    "name": "pt-BR-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Standard-A": {
                    "name": "pt-BR-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Standard-B": {
                    "name": "pt-BR-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Standard-C": {
                    "name": "pt-BR-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Wavenet-A": {
                    "name": "pt-BR-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Wavenet-B": {
                    "name": "pt-BR-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-BR-Wavenet-C": {
                    "name": "pt-BR-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "pt",
                        "pt-BR"
                    ]
                },
                "pt-PT-Standard-A": {
                    "name": "pt-PT-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "pt-PT-Standard-B": {
                    "name": "pt-PT-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "pt-PT-Standard-C": {
                    "name": "pt-PT-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "pt-PT-Standard-D": {
                    "name": "pt-PT-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "pt-PT-Wavenet-A": {
                    "name": "pt-PT-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "pt-PT-Wavenet-B": {
                    "name": "pt-PT-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "pt-PT-Wavenet-C": {
                    "name": "pt-PT-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "pt-PT-Wavenet-D": {
                    "name": "pt-PT-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "pt",
                        "pt-PT"
                    ]
                },
                "ro-RO-Standard-A": {
                    "name": "ro-RO-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ro",
                        "ro-RO"
                    ]
                },
                "ro-RO-Wavenet-A": {
                    "name": "ro-RO-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ro",
                        "ro-RO"
                    ]
                },
                "ru-RU-Standard-A": {
                    "name": "ru-RU-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Standard-B": {
                    "name": "ru-RU-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Standard-C": {
                    "name": "ru-RU-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Standard-D": {
                    "name": "ru-RU-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Standard-E": {
                    "name": "ru-RU-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Wavenet-A": {
                    "name": "ru-RU-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Wavenet-B": {
                    "name": "ru-RU-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Wavenet-C": {
                    "name": "ru-RU-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Wavenet-D": {
                    "name": "ru-RU-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "ru-RU-Wavenet-E": {
                    "name": "ru-RU-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "ru",
                        "ru-RU"
                    ]
                },
                "sk-SK-Standard-A": {
                    "name": "sk-SK-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "sk",
                        "sk-SK"
                    ]
                },
                "sk-SK-Wavenet-A": {
                    "name": "sk-SK-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "sk",
                        "sk-SK"
                    ]
                },
                "sr-RS-Standard-A": {
                    "name": "sr-RS-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "sr",
                        "sr-RS"
                    ]
                },
                "sv-SE-Standard-A": {
                    "name": "sv-SE-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Standard-B": {
                    "name": "sv-SE-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Standard-C": {
                    "name": "sv-SE-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Standard-D": {
                    "name": "sv-SE-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Standard-E": {
                    "name": "sv-SE-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Wavenet-A": {
                    "name": "sv-SE-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Wavenet-B": {
                    "name": "sv-SE-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Wavenet-C": {
                    "name": "sv-SE-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Wavenet-D": {
                    "name": "sv-SE-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "sv-SE-Wavenet-E": {
                    "name": "sv-SE-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "sv",
                        "sv-SE"
                    ]
                },
                "ta-IN-Standard-A": {
                    "name": "ta-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "ta-IN-Standard-B": {
                    "name": "ta-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "ta-IN-Standard-C": {
                    "name": "ta-IN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "ta-IN-Standard-D": {
                    "name": "ta-IN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "ta-IN-Wavenet-A": {
                    "name": "ta-IN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "ta-IN-Wavenet-B": {
                    "name": "ta-IN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "ta-IN-Wavenet-C": {
                    "name": "ta-IN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "ta-IN-Wavenet-D": {
                    "name": "ta-IN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "ta",
                        "ta-IN"
                    ]
                },
                "te-IN-Standard-A": {
                    "name": "te-IN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "te",
                        "te-IN"
                    ]
                },
                "te-IN-Standard-B": {
                    "name": "te-IN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "te",
                        "te-IN"
                    ]
                },
                "th-TH-Neural2-C": {
                    "name": "th-TH-Neural2-C",
                    "costMultiplier": 1,
                    "languages": [
                        "th",
                        "th-TH"
                    ]
                },
                "th-TH-Standard-A": {
                    "name": "th-TH-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "th",
                        "th-TH"
                    ]
                },
                "tr-TR-Standard-A": {
                    "name": "tr-TR-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Standard-B": {
                    "name": "tr-TR-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Standard-C": {
                    "name": "tr-TR-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Standard-D": {
                    "name": "tr-TR-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Standard-E": {
                    "name": "tr-TR-Standard-E",
                    "costMultiplier": 1,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Wavenet-A": {
                    "name": "tr-TR-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Wavenet-B": {
                    "name": "tr-TR-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Wavenet-C": {
                    "name": "tr-TR-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Wavenet-D": {
                    "name": "tr-TR-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "tr-TR-Wavenet-E": {
                    "name": "tr-TR-Wavenet-E",
                    "costMultiplier": 4,
                    "languages": [
                        "tr",
                        "tr-TR"
                    ]
                },
                "uk-UA-Standard-A": {
                    "name": "uk-UA-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "uk",
                        "uk-UA"
                    ]
                },
                "uk-UA-Wavenet-A": {
                    "name": "uk-UA-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "uk",
                        "uk-UA"
                    ]
                },
                "vi-VN-Neural2-A": {
                    "name": "vi-VN-Neural2-A",
                    "costMultiplier": 1,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Neural2-D": {
                    "name": "vi-VN-Neural2-D",
                    "costMultiplier": 1,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Standard-A": {
                    "name": "vi-VN-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Standard-B": {
                    "name": "vi-VN-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Standard-C": {
                    "name": "vi-VN-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Standard-D": {
                    "name": "vi-VN-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Wavenet-A": {
                    "name": "vi-VN-Wavenet-A",
                    "costMultiplier": 4,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Wavenet-B": {
                    "name": "vi-VN-Wavenet-B",
                    "costMultiplier": 4,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Wavenet-C": {
                    "name": "vi-VN-Wavenet-C",
                    "costMultiplier": 4,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "vi-VN-Wavenet-D": {
                    "name": "vi-VN-Wavenet-D",
                    "costMultiplier": 4,
                    "languages": [
                        "vi",
                        "vi-VN"
                    ]
                },
                "yue-HK-Standard-A": {
                    "name": "yue-HK-Standard-A",
                    "costMultiplier": 1,
                    "languages": [
                        "yue",
                        "yue-HK"
                    ]
                },
                "yue-HK-Standard-B": {
                    "name": "yue-HK-Standard-B",
                    "costMultiplier": 1,
                    "languages": [
                        "yue",
                        "yue-HK"
                    ]
                },
                "yue-HK-Standard-C": {
                    "name": "yue-HK-Standard-C",
                    "costMultiplier": 1,
                    "languages": [
                        "yue",
                        "yue-HK"
                    ]
                },
                "yue-HK-Standard-D": {
                    "name": "yue-HK-Standard-D",
                    "costMultiplier": 1,
                    "languages": [
                        "yue",
                        "yue-HK"
                    ]
                }
            },
            "languages": [
                "af",
                "af-ZA",
                "am",
                "am-ET",
                "ar",
                "ar-XA",
                "bg",
                "bg-BG",
                "bn",
                "bn-IN",
                "ca",
                "ca-ES",
                "cmn",
                "cmn-CN",
                "cmn-TW",
                "cs",
                "cs-CZ",
                "da",
                "da-DK",
                "de",
                "de-DE",
                "el",
                "el-GR",
                "en",
                "en-AU",
                "en-GB",
                "en-IN",
                "en-US",
                "es",
                "es-ES",
                "es-US",
                "eu",
                "eu-ES",
                "fi",
                "fi-FI",
                "fil",
                "fil-PH",
                "fr",
                "fr-CA",
                "fr-FR",
                "gl",
                "gl-ES",
                "gu",
                "gu-IN",
                "he",
                "he-IL",
                "hi",
                "hi-IN",
                "hu",
                "hu-HU",
                "id",
                "id-ID",
                "is",
                "is-IS",
                "it",
                "it-IT",
                "ja",
                "ja-JP",
                "kn",
                "kn-IN",
                "ko",
                "ko-KR",
                "lt",
                "lt-LT",
                "lv",
                "lv-LV",
                "ml",
                "ml-IN",
                "mr",
                "mr-IN",
                "ms",
                "ms-MY",
                "nb",
                "nb-NO",
                "nl",
                "nl-BE",
                "nl-NL",
                "pa",
                "pa-IN",
                "pl",
                "pl-PL",
                "pt",
                "pt-BR",
                "pt-PT",
                "ro",
                "ro-RO",
                "ru",
                "ru-RU",
                "sk",
                "sk-SK",
                "sr",
                "sr-RS",
                "sv",
                "sv-SE",
                "ta",
                "ta-IN",
                "te",
                "te-IN",
                "th",
                "th-TH",
                "tr",
                "tr-TR",
                "uk",
                "uk-UA",
                "vi",
                "vi-VN",
                "yue",
                "yue-HK"
            ],
            "type": "SPEECH",
            "defaultVoices": {
                "af": "af-ZA-Standard-A",
                "af-ZA": "af-ZA-Standard-A",
                "ar": "ar-XA-Wavenet-A",
                "ar-XA": "ar-XA-Wavenet-A",
                "cs": "cs-CZ-Wavenet-A",
                "cs-CZ": "cs-CZ-Wavenet-A",
                "da": "da-DK-Wavenet-A",
                "da-DK": "da-DK-Wavenet-A",
                "de": "de-DE-Wavenet-B",
                "de-DE": "de-DE-Wavenet-B",
                "el": "el-GR-Wavenet-A",
                "el-GR": "el-GR-Wavenet-A",
                "en": "en-US-Wavenet-F",
                "en-GB": "en-GB-Wavenet-A",
                "en-US": "en-US-Wavenet-F",
                "es": "es-ES-Wavenet-D",
                "es-ES": "es-ES-Wavenet-D",
                "es-US": "es-US-Wavenet-C",
                "fi": "fi-FI-Wavenet-A",
                "fi-FI": "fi-FI-Wavenet-A",
                "fr": "fr-FR-Wavenet-C",
                "fr-CA": "fr-CA-Wavenet-B",
                "fr-FR": "fr-FR-Wavenet-C",
                "hu": "hu-HU-Wavenet-A",
                "hu-HU": "hu-HU-Wavenet-A",
                "is": "is-IS-Standard-A",
                "is-IS": "is-IS-Standard-A",
                "it": "it-IT-Wavenet-C",
                "it-IT": "it-IT-Wavenet-C",
                "lt": "lt-LT-Standard-A",
                "lt-LT": "lt-LT-Standard-A",
                "nb": "nb-NO-Wavenet-B",
                "nb-NO": "nb-NO-Wavenet-B",
                "nl": "nl-NL-Wavenet-E",
                "nl-NL": "nl-NL-Wavenet-E",
                "pl": "pl-PL-Wavenet-A",
                "pl-PL": "pl-PL-Wavenet-A",
                "pt": "pt-PT-Wavenet-B",
                "pt-BR": "pt-BR-Wavenet-B",
                "pt-PT": "pt-PT-Wavenet-B",
                "ru": "ru-RU-Wavenet-D",
                "ru-RU": "ru-RU-Wavenet-D",
                "sv": "sv-SE-Wavenet-D",
                "sv-SE": "sv-SE-Wavenet-D",
                "tr": "tr-TR-Wavenet-D",
                "tr-TR": "tr-TR-Wavenet-D",
                "uk": "uk-UA-Wavenet-A",
                "uk-UA": "uk-UA-Wavenet-A"
            }
        },
        "svg-repo user interface": {
            "canonicalName": "svg-repo user interface",
            "names": [
                "svg-repo user interface",
                "svg-repo-ui"
            ],
            "languages": [
                "de",
                "en"
            ],
            "type": "I18N"
        },
        "svg-repo graphics": {
            "canonicalName": "svg-repo graphics",
            "names": [
                "svg-repo graphics",
                "svg-repo-graphics"
            ],
            "languages": [
                "af",
                "af-ZA",
                "ar",
                "ar-XA",
                "cs",
                "cs-CZ",
                "da",
                "da-DK",
                "de",
                "de-DE",
                "el",
                "el-GR",
                "en",
                "en-GB",
                "en-US",
                "es",
                "es-ES",
                "es-US",
                "fi",
                "fi-FI",
                "fr",
                "fr-CA",
                "fr-FR",
                "hu",
                "hu-HU",
                "is",
                "is-IS",
                "it",
                "it-IT",
                "lt",
                "lt-LT",
                "nb",
                "nb-NO",
                "nl",
                "nl-NL",
                "pl",
                "pl-PL",
                "pt",
                "pt-BR",
                "pt-PT",
                "ru",
                "ru-RU",
                "sv",
                "sv-SE",
                "tr",
                "tr-TR",
                "uk",
                "uk-UA"
            ],
            "type": "OTHER"
        },
        "tactonom-reader canned text system message service": {
            "canonicalName": "tactonom-reader canned text system message service",
            "names": [
                "tactonom-reader canned text system message service",
                "reader-canned-text"
            ],
            "languages": [
                "af-ZA",
                "cs-CZ",
                "da-DK",
                "de-DE",
                "en-US",
                "fr-FR",
                "nl-NL",
                "pl-PL",
                "pt-PT"
            ],
            "type": "I18N"
        },
        "liblouis braille translator": {
            "canonicalName": "liblouis braille translator",
            "names": [
                "liblouis braille translator",
                "liblouis"
            ],
            "languages": [
                "af",
                "ar",
                "as",
                "aw",
                "ba",
                "be",
                "bg",
                "bh",
                "bo",
                "br",
                "ca",
                "chr",
                "ckb",
                "cmn-CN",
                "cmn-TW",
                "cop",
                "cs",
                "cy",
                "da",
                "de",
                "dra",
                "el",
                "en",
                "en-CA",
                "en-GB",
                "en-US",
                "eo",
                "es",
                "et",
                "fa",
                "fi",
                "fr",
                "ga",
                "gd",
                "gon",
                "gu",
                "haw",
                "hbo",
                "he",
                "hi",
                "hr",
                "hu",
                "hy",
                "ipa",
                "is",
                "it",
                "iu",
                "ja",
                "ka",
                "kh",
                "kk",
                "km",
                "ko",
                "kok",
                "kru",
                "ks",
                "lg",
                "lo",
                "lt",
                "lv",
                "mi",
                "ml",
                "mn",
                "mr",
                "ms",
                "mt",
                "mul-IL",
                "mun",
                "mwr",
                "my",
                "nb",
                "ne",
                "nl",
                "nn",
                "np",
                "nso",
                "or",
                "pi",
                "pl",
                "pt",
                "pu",
                "ro",
                "ru",
                "rw",
                "sa",
                "sah",
                "se",
                "si",
                "sin",
                "sk",
                "sl",
                "sr",
                "st",
                "sv",
                "syr",
                "ta",
                "te",
                "th",
                "tlg",
                "tn",
                "tr",
                "tt",
                "uga",
                "uk",
                "uz",
                "ve",
                "vi",
                "xh",
                "xlu",
                "yi",
                "yue-HK",
                "zh",
                "zu"
            ],
            "type": "BRAILLE",
            "translationTables": {
                "Es-Es-G0.utb": {
                    "filename": "Es-Es-G0.utb",
                    "displayName": "Spanish computer braille",
                    "indexName": "Spanish, computer",
                    "computer": true,
                    "language": "es"
                },
                "IPA.utb": {
                    "filename": "IPA.utb",
                    "computer": false,
                    "language": "ipa"
                },
                "Lv-Lv-g1.utb": {
                    "filename": "Lv-Lv-g1.utb",
                    "computer": false,
                    "language": "lv"
                },
                "Pl-Pl-g1.utb": {
                    "filename": "Pl-Pl-g1.utb",
                    "computer": false,
                    "language": "pl"
                },
                "afr-za-g1.ctb": {
                    "filename": "afr-za-g1.ctb",
                    "displayName": "Afrikaans uncontracted braille",
                    "nativeName": "Afrikaans onverkort",
                    "indexName": "Afrikaans, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "af",
                    "contraction": "no"
                },
                "afr-za-g2.ctb": {
                    "filename": "afr-za-g2.ctb",
                    "displayName": "Afrikaans contracted braille",
                    "nativeName": "Afrikaans verkort",
                    "indexName": "Afrikaans, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "af",
                    "contraction": "full"
                },
                "akk-borger.utb": {
                    "filename": "akk-borger.utb",
                    "displayName": "Akkadian braille according to Borger system",
                    "indexName": "Akkadian, Borger",
                    "computer": false,
                    "dots": 6,
                    "language": "xlu",
                    "contraction": "no"
                },
                "akk.utb": {
                    "filename": "akk.utb",
                    "displayName": "Akkadian braille according to U.S. system",
                    "indexName": "Akkadian, U.S.",
                    "computer": false,
                    "dots": 6,
                    "language": "xlu",
                    "contraction": "no"
                },
                "ar-ar-comp8.utb": {
                    "filename": "ar-ar-comp8.utb",
                    "displayName": "Arabic computer braille",
                    "indexName": "Arabic, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "ar"
                },
                "ar-ar-g1.utb": {
                    "filename": "ar-ar-g1.utb",
                    "computer": false,
                    "language": "ar"
                },
                "ar-ar-g2.ctb": {
                    "filename": "ar-ar-g2.ctb",
                    "displayName": "Arabic contracted braille",
                    "indexName": "Arabic, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "ar",
                    "contraction": "full"
                },
                "as-in-g1.utb": {
                    "filename": "as-in-g1.utb",
                    "computer": false,
                    "language": "as"
                },
                "aw-in-g1.utb": {
                    "filename": "aw-in-g1.utb",
                    "computer": false,
                    "language": "aw"
                },
                "ba.utb": {
                    "filename": "ba.utb",
                    "displayName": "Bashkir braille",
                    "indexName": "Bashkir",
                    "computer": false,
                    "language": "ba"
                },
                "be-in-g1.utb": {
                    "filename": "be-in-g1.utb",
                    "computer": false,
                    "language": "be"
                },
                "bel-comp.utb": {
                    "filename": "bel-comp.utb",
                    "displayName": "Belarusian computer braille",
                    "indexName": "Belarusian, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "be"
                },
                "bel-detailed.utb": {
                    "filename": "bel-detailed.utb",
                    "displayName": "Belarusian braille with indication of capitals",
                    "indexName": "Belarusian, with capitals",
                    "computer": false,
                    "dots": 6,
                    "language": "be",
                    "contraction": "no"
                },
                "bel.utb": {
                    "filename": "bel.utb",
                    "displayName": "Belarusian braille",
                    "indexName": "Belarusian",
                    "computer": false,
                    "dots": 6,
                    "language": "be",
                    "contraction": "no"
                },
                "bg.ctb": {
                    "filename": "bg.ctb",
                    "computer": false,
                    "language": "bg"
                },
                "bg.utb": {
                    "filename": "bg.utb",
                    "displayName": "Bulgarian braille",
                    "indexName": "Bulgarian",
                    "computer": false,
                    "dots": 6,
                    "language": "bg",
                    "contraction": "no"
                },
                "bh.ctb": {
                    "filename": "bh.ctb",
                    "computer": false,
                    "language": "bh"
                },
                "bo.ctb": {
                    "filename": "bo.ctb",
                    "computer": false,
                    "language": "bo"
                },
                "br-in-g1.utb": {
                    "filename": "br-in-g1.utb",
                    "computer": false,
                    "language": "br"
                },
                "ca-g1.ctb": {
                    "filename": "ca-g1.ctb",
                    "computer": false,
                    "language": "ca"
                },
                "chr-us-g1.ctb": {
                    "filename": "chr-us-g1.ctb",
                    "displayName": "Cherokee braille",
                    "indexName": "Cherokee",
                    "computer": false,
                    "grade": "1",
                    "language": "chr",
                    "contraction": "no"
                },
                "ckb-g1.ctb": {
                    "filename": "ckb-g1.ctb",
                    "computer": false,
                    "language": "ckb"
                },
                "cop-eg-comp8.utb": {
                    "filename": "cop-eg-comp8.utb",
                    "displayName": "Coptic computer braille",
                    "indexName": "Coptic, computer ",
                    "computer": true,
                    "dots": 8,
                    "updated": "Mon Aug 31 00:00:00 CEST 2020",
                    "language": "cop"
                },
                "cs-comp8.utb": {
                    "filename": "cs-comp8.utb",
                    "displayName": "Czech computer braille",
                    "indexName": "Czech, computer",
                    "computer": true,
                    "grade": "0",
                    "dots": 8,
                    "language": "cs"
                },
                "cs-g1.ctb": {
                    "filename": "cs-g1.ctb",
                    "computer": false,
                    "language": "cs"
                },
                "cuneiform-transliterated.utb": {
                    "filename": "cuneiform-transliterated.utb",
                    "displayName": "Transliterated Cuneiform braille",
                    "indexName": "Cuneiform, transliterated",
                    "computer": false,
                    "dots": 6,
                    "language": "xlu",
                    "contraction": "no"
                },
                "cy-cy-g1.utb": {
                    "filename": "cy-cy-g1.utb",
                    "displayName": "Welsh uncontracted braille",
                    "indexName": "Welsh, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "cy",
                    "contraction": "no"
                },
                "cy-cy-g2.ctb": {
                    "filename": "cy-cy-g2.ctb",
                    "computer": false,
                    "language": "cy"
                },
                "da-dk-g08.ctb": {
                    "filename": "da-dk-g08.ctb",
                    "displayName": "Danish computer braille (2022 standard)",
                    "nativeName": "Dansk computer 2022",
                    "indexName": "Danish, computer, 2022",
                    "computer": true,
                    "grade": "0",
                    "dots": 8,
                    "language": "da",
                    "contraction": "no"
                },
                "da-dk-g08_1993.ctb": {
                    "filename": "da-dk-g08_1993.ctb",
                    "displayName": "Danish computer braille (1993 standard)",
                    "nativeName": "Dansk computer 1993",
                    "indexName": "Danish, computer, 1993",
                    "computer": true,
                    "grade": "0",
                    "dots": 8,
                    "language": "da",
                    "contraction": "no"
                },
                "da-dk-g16-lit_1993.ctb": {
                    "filename": "da-dk-g16-lit_1993.ctb",
                    "displayName": "Danish 6-dot uncontracted braille (1993 standard)",
                    "nativeName": "Dansk fuldskrift 6-punkt 1993",
                    "indexName": "Danish, uncontracted, 6-dot, 1993",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "da",
                    "contraction": "no"
                },
                "da-dk-g16.ctb": {
                    "filename": "da-dk-g16.ctb",
                    "displayName": "Danish 6-dot uncontracted braille (2022 standard)",
                    "nativeName": "Dansk fuldskrift 6-punkt 2022",
                    "indexName": "Danish, uncontracted, 6-dot, 2022",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "da",
                    "contraction": "no"
                },
                "da-dk-g16_1993.ctb": {
                    "filename": "da-dk-g16_1993.ctb",
                    "displayName": "Danish 6-dot uncontracted braille (1993 standard)",
                    "nativeName": "Dansk fuldskrift 6-punkt 1993",
                    "indexName": "Danish, uncontracted, 6-dot, 1993",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "da",
                    "contraction": "no"
                },
                "da-dk-g18.ctb": {
                    "filename": "da-dk-g18.ctb",
                    "displayName": "Danish 8-dot uncontracted braille (2022 standard)",
                    "nativeName": "Dansk fuldskrift 8-punkt 2022",
                    "indexName": "Danish, uncontracted, 8-dot, 2022",
                    "computer": false,
                    "grade": "1",
                    "dots": 8,
                    "language": "da",
                    "contraction": "no"
                },
                "da-dk-g18_1993.ctb": {
                    "filename": "da-dk-g18_1993.ctb",
                    "displayName": "Danish 8-dot uncontracted braille (1993 standard)",
                    "nativeName": "Dansk fuldskrift 8-punkt 1993",
                    "indexName": "Danish, uncontracted, 8-dot, 1993",
                    "computer": false,
                    "grade": "1",
                    "dots": 8,
                    "language": "da",
                    "contraction": "no"
                },
                "da-dk-g26-lit_1993.ctb": {
                    "filename": "da-dk-g26-lit_1993.ctb",
                    "displayName": "Danish 6-dot contracted braille (1993 standard)",
                    "nativeName": "Dansk forkortet 6-punkt 1993",
                    "indexName": "Danish, contracted, 6-dot, 1993",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "language": "da",
                    "contraction": "full"
                },
                "da-dk-g26.ctb": {
                    "filename": "da-dk-g26.ctb",
                    "displayName": "Danish 6-dot contracted braille (2022 standard)",
                    "nativeName": "Dansk forkortet 6-punkt 2022",
                    "indexName": "Danish, contracted, 6-dot, 2022",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "language": "da",
                    "contraction": "full"
                },
                "da-dk-g26_1993.ctb": {
                    "filename": "da-dk-g26_1993.ctb",
                    "displayName": "Danish 6-dot contracted braille (1993 standard)",
                    "nativeName": "Dansk forkortet 6-punkt 1993",
                    "indexName": "Danish, contracted, 6-dot, 1993",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "language": "da",
                    "contraction": "full"
                },
                "da-dk-g26l-lit_1993.ctb": {
                    "filename": "da-dk-g26l-lit_1993.ctb",
                    "displayName": "Danish 6-dot partially contracted braille (1993 standard)",
                    "nativeName": "Dansk lille forkortelse 6-punkt litterær 1993",
                    "indexName": "Danish, partially contracted, 6-dot, 1993",
                    "computer": false,
                    "grade": "1.5",
                    "dots": 6,
                    "language": "da",
                    "contraction": "partial"
                },
                "da-dk-g26l_1993.ctb": {
                    "filename": "da-dk-g26l_1993.ctb",
                    "displayName": "Danish 6-dot partially contracted braille (1993 standard)",
                    "nativeName": "Dansk lille forkortelse 1993",
                    "indexName": "Danish, partially contracted, 6-dot, 1993",
                    "computer": false,
                    "grade": "1.5",
                    "dots": 6,
                    "language": "da",
                    "contraction": "partial"
                },
                "da-dk-g28.ctb": {
                    "filename": "da-dk-g28.ctb",
                    "displayName": "Danish 8-dot contracted braille (2022 standard)",
                    "nativeName": "Dansk forkortet 8-punkt 2022",
                    "indexName": "Danish, contracted, 8-dot, 2022",
                    "computer": false,
                    "grade": "2",
                    "dots": 8,
                    "language": "da",
                    "contraction": "full"
                },
                "da-dk-g28_1993.ctb": {
                    "filename": "da-dk-g28_1993.ctb",
                    "displayName": "Danish 8-dot contracted braille (1993 standard)",
                    "nativeName": "Dansk forkortet 8-punkt 1993",
                    "indexName": "Danish, contracted, 8-dot, 1993",
                    "computer": false,
                    "grade": "2",
                    "dots": 8,
                    "language": "da",
                    "contraction": "full"
                },
                "da-dk-g28l_1993.ctb": {
                    "filename": "da-dk-g28l_1993.ctb",
                    "displayName": "Danish 8-dot partially contracted braille (1993 standard)",
                    "nativeName": "Dansk lille forkortelse 8-punkt 1993",
                    "indexName": "Danish, partially contracted, 8-dot, 1993",
                    "computer": false,
                    "grade": "1.5",
                    "dots": 8,
                    "language": "da",
                    "contraction": "partial"
                },
                "de-chess.ctb": {
                    "filename": "de-chess.ctb",
                    "computer": false,
                    "language": "de"
                },
                "de-comp6.utb": {
                    "filename": "de-comp6.utb",
                    "displayName": "German 6-dot computer braille",
                    "nativeName": "Deutsches 6-Punkte Computerbraille",
                    "indexName": "German, computer, 6-dot",
                    "computer": true,
                    "dots": 6,
                    "language": "de"
                },
                "de-de-comp8.ctb": {
                    "filename": "de-de-comp8.ctb",
                    "displayName": "German 8-dot computer braille",
                    "nativeName": "Eurobraille",
                    "indexName": "German, computer, 8-dot",
                    "computer": true,
                    "dots": 8,
                    "language": "de"
                },
                "de-g0-detailed.utb": {
                    "filename": "de-g0-detailed.utb",
                    "displayName": "German uncontracted braille with indication of capitals",
                    "nativeName": "Deutsche Basisschrift",
                    "indexName": "German, uncontracted, with capitals",
                    "computer": false,
                    "grade": "0",
                    "language": "de",
                    "contraction": "no"
                },
                "de-g0.utb": {
                    "filename": "de-g0.utb",
                    "displayName": "German uncontracted braille",
                    "nativeName": "Deutsche Basisschrift",
                    "indexName": "German, uncontracted",
                    "computer": false,
                    "grade": "0",
                    "language": "de",
                    "contraction": "no"
                },
                "de-g1-detailed.ctb": {
                    "filename": "de-g1-detailed.ctb",
                    "displayName": "German partially contracted braille with indication of capitals",
                    "nativeName": "Deutsche Vollschrift",
                    "indexName": "German, partially contracted, with capitals",
                    "computer": false,
                    "grade": "1",
                    "language": "de",
                    "contraction": "partial"
                },
                "de-g1.ctb": {
                    "filename": "de-g1.ctb",
                    "displayName": "German partially contracted braille",
                    "nativeName": "Deutsche Vollschrift",
                    "indexName": "German, partially contracted",
                    "computer": false,
                    "grade": "1",
                    "language": "de",
                    "contraction": "partial"
                },
                "de-g2-detailed.ctb": {
                    "filename": "de-g2-detailed.ctb",
                    "displayName": "German contracted braille with indication of capitals",
                    "nativeName": "Deutsche Kurzschrift",
                    "indexName": "German, contracted, with capitals",
                    "computer": false,
                    "grade": "2",
                    "language": "de",
                    "contraction": "full"
                },
                "de-g2.ctb": {
                    "filename": "de-g2.ctb",
                    "displayName": "German contracted braille",
                    "nativeName": "Deutsche Kurzschrift",
                    "indexName": "German, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "de",
                    "contraction": "full"
                },
                "dra.ctb": {
                    "filename": "dra.ctb",
                    "computer": false,
                    "language": "dra"
                },
                "el.ctb": {
                    "filename": "el.ctb",
                    "displayName": "Greek braille",
                    "nativeName": "ελληνικό σύστημα μπράιγ",
                    "indexName": "Greek",
                    "computer": false,
                    "dots": 6,
                    "language": "el",
                    "contraction": "full"
                },
                "en-GB-g2.ctb": {
                    "filename": "en-GB-g2.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en-chess.ctb": {
                    "filename": "en-chess.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en-gb-comp8.ctb": {
                    "filename": "en-gb-comp8.ctb",
                    "displayName": "English computer braille as used in the U.K.",
                    "indexName": "English, U.K., computer",
                    "computer": true,
                    "dots": 8,
                    "language": "en-GB"
                },
                "en-gb-g1.utb": {
                    "filename": "en-gb-g1.utb",
                    "displayName": "English uncontracted braille as used in the U.K.",
                    "nativeName": "British English grade 1",
                    "indexName": "English, U.K., uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "en-GB",
                    "contraction": "no"
                },
                "en-in-g1.ctb": {
                    "filename": "en-in-g1.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en-nabcc.utb": {
                    "filename": "en-nabcc.utb",
                    "displayName": "North American Braille Computer Code",
                    "indexName": "English, U.S., computer, NABCC",
                    "computer": true,
                    "dots": 8,
                    "language": "en"
                },
                "en-ueb-g1.ctb": {
                    "filename": "en-ueb-g1.ctb",
                    "displayName": "Unified English uncontracted braille",
                    "nativeName": "Unified English braille, grade 1",
                    "indexName": "English, unified, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "en",
                    "contraction": "no"
                },
                "en-ueb-g2.ctb": {
                    "filename": "en-ueb-g2.ctb",
                    "displayName": "Unified English contracted braille",
                    "nativeName": "Unified English braille, grade 2",
                    "indexName": "English, unified, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "en",
                    "contraction": "full"
                },
                "en-ueb-math.ctb": {
                    "filename": "en-ueb-math.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en-us-comp6.ctb": {
                    "filename": "en-us-comp6.ctb",
                    "displayName": "English 6-dot computer braille as used in the U.S.",
                    "nativeName": "U.S. English Computer Braille Code (CBC)",
                    "indexName": "English, U.S., computer, 6-dot",
                    "computer": true,
                    "dots": 6,
                    "language": "en-US"
                },
                "en-us-comp8-ext.utb": {
                    "filename": "en-us-comp8-ext.utb",
                    "computer": false,
                    "language": "en"
                },
                "en-us-comp8.ctb": {
                    "filename": "en-us-comp8.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en-us-g1.ctb": {
                    "filename": "en-us-g1.ctb",
                    "displayName": "English uncontracted braille as used in the U.S.",
                    "nativeName": "U.S. English grade 1 (EBAE)",
                    "indexName": "English, U.S., uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "en-US",
                    "contraction": "no"
                },
                "en-us-g2.ctb": {
                    "filename": "en-us-g2.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en-us-interline.ctb": {
                    "filename": "en-us-interline.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en-us-mathtext.ctb": {
                    "filename": "en-us-mathtext.ctb",
                    "computer": false,
                    "language": "en"
                },
                "en_CA.ctb": {
                    "filename": "en_CA.ctb",
                    "computer": false,
                    "language": "en-CA"
                },
                "eo-g1-x-system.ctb": {
                    "filename": "eo-g1-x-system.ctb",
                    "displayName": "Esperanto x-system braille",
                    "indexName": "Esperanto x-system",
                    "computer": false,
                    "grade": "1",
                    "language": "eo"
                },
                "eo-g1.ctb": {
                    "filename": "eo-g1.ctb",
                    "computer": false,
                    "language": "eo"
                },
                "es-g1.ctb": {
                    "filename": "es-g1.ctb",
                    "computer": false,
                    "language": "es"
                },
                "es-g2.ctb": {
                    "filename": "es-g2.ctb",
                    "displayName": "Spanish contracted braille",
                    "indexName": "Spanish, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "es",
                    "contraction": "full"
                },
                "et-g0.utb": {
                    "filename": "et-g0.utb",
                    "computer": false,
                    "language": "et"
                },
                "et.ctb": {
                    "filename": "et.ctb",
                    "computer": false,
                    "language": "et"
                },
                "fa-ir-comp8.ctb": {
                    "filename": "fa-ir-comp8.ctb",
                    "displayName": "Persian computer braille",
                    "indexName": "Persian, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "fa"
                },
                "fa-ir-g1.utb": {
                    "filename": "fa-ir-g1.utb",
                    "displayName": "Persian braille",
                    "indexName": "Persian",
                    "computer": false,
                    "grade": "1",
                    "language": "fa",
                    "contraction": "no"
                },
                "fi-fi-8dot.ctb": {
                    "filename": "fi-fi-8dot.ctb",
                    "displayName": "Finnish computer braille",
                    "indexName": "Finnish, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "fi"
                },
                "fi.utb": {
                    "filename": "fi.utb",
                    "displayName": "Finnish braille",
                    "nativeName": "Suomenkielinen 6-pisteen pistekirjoitustaulukko",
                    "indexName": "Finnish",
                    "computer": false,
                    "dots": 6,
                    "updated": "Mon Jun 17 00:00:00 CEST 2024",
                    "language": "fi",
                    "contraction": "no"
                },
                "fil-g2.ctb": {
                    "filename": "fil-g2.ctb",
                    "displayName": "Filipino contracted braille",
                    "nativeName": "Filipino braille, grado 2",
                    "indexName": "Filipino, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "tlg",
                    "contraction": "full"
                },
                "fr-bfu-comp6.utb": {
                    "filename": "fr-bfu-comp6.utb",
                    "displayName": "French uncontracted braille",
                    "indexName": "French, uncontracted",
                    "computer": false,
                    "dots": 6,
                    "updated": "Sun Feb 21 00:00:00 CET 2021",
                    "language": "fr",
                    "contraction": "no"
                },
                "fr-bfu-comp8.utb": {
                    "filename": "fr-bfu-comp8.utb",
                    "displayName": "French computer braille",
                    "indexName": "French, computer",
                    "computer": true,
                    "dots": 8,
                    "updated": "Sun Mar 04 00:00:00 CET 2018",
                    "language": "fr"
                },
                "fr-bfu-g2.ctb": {
                    "filename": "fr-bfu-g2.ctb",
                    "displayName": "French contracted braille",
                    "indexName": "French, contracted",
                    "computer": false,
                    "grade": "2",
                    "updated": "Wed May 16 00:00:00 CEST 2018",
                    "language": "fr",
                    "contraction": "full"
                },
                "ga-g1.utb": {
                    "filename": "ga-g1.utb",
                    "displayName": "Irish uncontracted braille",
                    "indexName": "Irish, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "ga",
                    "contraction": "no"
                },
                "ga-g2.ctb": {
                    "filename": "ga-g2.ctb",
                    "displayName": "Irish contracted braille",
                    "indexName": "Irish, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "ga",
                    "contraction": "full"
                },
                "gd.ctb": {
                    "filename": "gd.ctb",
                    "computer": false,
                    "language": "gd"
                },
                "gon.ctb": {
                    "filename": "gon.ctb",
                    "computer": false,
                    "language": "gon"
                },
                "grc-international-en-composed.utb": {
                    "filename": "grc-international-en-composed.utb",
                    "displayName": "Greek internationalized braille with single-cell accented letters as used by English speakers",
                    "indexName": "Greek, international, English, composed",
                    "computer": false,
                    "dots": 6,
                    "language": "en",
                    "contraction": "no"
                },
                "grc-international-en.utb": {
                    "filename": "grc-international-en.utb",
                    "displayName": "Greek internationalized braille with 2-cell accented letters as used by English speakers",
                    "indexName": "Greek, international, English, decomposed",
                    "computer": false,
                    "dots": 6,
                    "language": "en",
                    "contraction": "no"
                },
                "grc-international-es.utb": {
                    "filename": "grc-international-es.utb",
                    "displayName": "Spanish braille for Greek text",
                    "indexName": "Greek, international, Spanish",
                    "computer": false,
                    "dots": 8,
                    "language": "es",
                    "contraction": "no"
                },
                "gu-in-g1.utb": {
                    "filename": "gu-in-g1.utb",
                    "computer": false,
                    "language": "gu"
                },
                "haw-us-g1.ctb": {
                    "filename": "haw-us-g1.ctb",
                    "displayName": "Hawaiian braille",
                    "indexName": "Hawaiian",
                    "computer": false,
                    "grade": "1",
                    "language": "haw",
                    "contraction": "no"
                },
                "hbo.utb": {
                    "filename": "hbo.utb",
                    "displayName": "Biblical Hebrew braille",
                    "indexName": "Hebrew, Biblical",
                    "computer": false,
                    "dots": 6,
                    "language": "hbo",
                    "contraction": "no"
                },
                "he-IL-comp8.utb": {
                    "filename": "he-IL-comp8.utb",
                    "displayName": "Hebrew computer braille",
                    "indexName": "Hebrew, modern, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "he"
                },
                "he-IL.utb": {
                    "filename": "he-IL.utb",
                    "displayName": "Israeli braille",
                    "indexName": "Hebrew, modern",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "mul-IL",
                    "contraction": "no"
                },
                "hi-in-g1.utb": {
                    "filename": "hi-in-g1.utb",
                    "computer": false,
                    "language": "hi"
                },
                "hr-comp8.utb": {
                    "filename": "hr-comp8.utb",
                    "computer": false,
                    "language": "hr"
                },
                "hr-g1.ctb": {
                    "filename": "hr-g1.ctb",
                    "computer": false,
                    "language": "hr"
                },
                "hu-hu-comp8.ctb": {
                    "filename": "hu-hu-comp8.ctb",
                    "displayName": "Hungarian computer braille",
                    "indexName": "Hungarian, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "hu"
                },
                "hu-hu-g1.ctb": {
                    "filename": "hu-hu-g1.ctb",
                    "computer": false,
                    "language": "hu"
                },
                "hu-hu-g2.ctb": {
                    "filename": "hu-hu-g2.ctb",
                    "displayName": "Hungarian contracted braille",
                    "indexName": "Hungarian, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "hu",
                    "contraction": "full"
                },
                "hy.ctb": {
                    "filename": "hy.ctb",
                    "computer": false,
                    "language": "hy"
                },
                "is.ctb": {
                    "filename": "is.ctb",
                    "computer": false,
                    "language": "is"
                },
                "it-it-comp6.utb": {
                    "filename": "it-it-comp6.utb",
                    "computer": false,
                    "language": "it"
                },
                "it-it-comp8.utb": {
                    "filename": "it-it-comp8.utb",
                    "displayName": "Italian computer braille",
                    "indexName": "Italian, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "it"
                },
                "iu-ca-g1.ctb": {
                    "filename": "iu-ca-g1.ctb",
                    "displayName": "Inuktitut braille",
                    "indexName": "Inuktitut",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "iu",
                    "contraction": "no"
                },
                "ja-kantenji-ucs2.utb": {
                    "filename": "ja-kantenji-ucs2.utb",
                    "displayName": "Kantenji",
                    "indexName": "Japanese, Kantenji",
                    "computer": false,
                    "grade": "1",
                    "dots": 8,
                    "language": "ja",
                    "contraction": "no"
                },
                "ja-kantenji.utb": {
                    "filename": "ja-kantenji.utb",
                    "displayName": "Kantenji",
                    "indexName": "Japanese, Kantenji",
                    "computer": false,
                    "grade": "1",
                    "dots": 8,
                    "language": "ja",
                    "contraction": "no"
                },
                "ka-in-g1.utb": {
                    "filename": "ka-in-g1.utb",
                    "computer": false,
                    "language": "ka"
                },
                "ka.utb": {
                    "filename": "ka.utb",
                    "displayName": "Georgian braille",
                    "indexName": "Georgian",
                    "computer": false,
                    "dots": 6,
                    "language": "ka"
                },
                "kh-in-g1.utb": {
                    "filename": "kh-in-g1.utb",
                    "computer": false,
                    "language": "kh"
                },
                "kk.utb": {
                    "filename": "kk.utb",
                    "displayName": "Kazakh braille",
                    "indexName": "Kazakh",
                    "computer": false,
                    "dots": 6,
                    "language": "kk",
                    "contraction": "no"
                },
                "km-g1.utb": {
                    "filename": "km-g1.utb",
                    "displayName": "Khmer braille",
                    "indexName": "Khmer",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "updated": "Tue Nov 17 00:00:00 CET 2020",
                    "language": "km",
                    "contraction": "no"
                },
                "ko-2006-g1.ctb": {
                    "filename": "ko-2006-g1.ctb",
                    "displayName": "Korean uncontracted braille (2006 standard)",
                    "indexName": "Korean, uncontracted, 2006",
                    "computer": false,
                    "grade": "1",
                    "language": "ko",
                    "contraction": "no"
                },
                "ko-2006-g2.ctb": {
                    "filename": "ko-2006-g2.ctb",
                    "displayName": "Korean contracted braille (2006 standard)",
                    "indexName": "Korean, contracted, 2006",
                    "computer": false,
                    "grade": "2",
                    "language": "ko",
                    "contraction": "full"
                },
                "ko-g1.ctb": {
                    "filename": "ko-g1.ctb",
                    "displayName": "Korean uncontracted braille",
                    "indexName": "Korean, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "ko",
                    "contraction": "no"
                },
                "ko-g2.ctb": {
                    "filename": "ko-g2.ctb",
                    "displayName": "Korean contracted braille",
                    "indexName": "Korean, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "ko",
                    "contraction": "full"
                },
                "kok.ctb": {
                    "filename": "kok.ctb",
                    "computer": false,
                    "language": "kok"
                },
                "kru.ctb": {
                    "filename": "kru.ctb",
                    "computer": false,
                    "language": "kru"
                },
                "ks-in-g1.utb": {
                    "filename": "ks-in-g1.utb",
                    "computer": false,
                    "language": "ks"
                },
                "lg-ug-g1.utb": {
                    "filename": "lg-ug-g1.utb",
                    "displayName": "Luganda braille",
                    "indexName": "Luganda",
                    "computer": false,
                    "dots": 6,
                    "language": "lg",
                    "contraction": "no"
                },
                "lo-g1.utb": {
                    "filename": "lo-g1.utb",
                    "displayName": "Lao braille",
                    "indexName": "Lao",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "updated": "Mon May 08 00:00:00 CEST 2023",
                    "language": "lo",
                    "contraction": "no"
                },
                "lt-6dot.utb": {
                    "filename": "lt-6dot.utb",
                    "computer": false,
                    "language": "lt"
                },
                "lt.ctb": {
                    "filename": "lt.ctb",
                    "computer": false,
                    "language": "lt"
                },
                "mao-nz-g1.ctb": {
                    "filename": "mao-nz-g1.ctb",
                    "displayName": "Maori braille",
                    "indexName": "Maori",
                    "computer": false,
                    "grade": "1",
                    "language": "mi",
                    "contraction": "no"
                },
                "ml-in-g1.utb": {
                    "filename": "ml-in-g1.utb",
                    "computer": false,
                    "language": "ml"
                },
                "mn-MN-g1.utb": {
                    "filename": "mn-MN-g1.utb",
                    "displayName": "Mongolian uncontracted braille",
                    "indexName": "Mongolian, uncontracted",
                    "computer": false,
                    "dots": 8,
                    "language": "mn",
                    "contraction": "no"
                },
                "mn-MN-g2.ctb": {
                    "filename": "mn-MN-g2.ctb",
                    "displayName": "Mongolian contracted braille",
                    "indexName": "Mongolian, contracted",
                    "computer": false,
                    "dots": 8,
                    "language": "mn",
                    "contraction": "full"
                },
                "mn-in-g1.utb": {
                    "filename": "mn-in-g1.utb",
                    "computer": false,
                    "language": "mn"
                },
                "mr-in-g1.utb": {
                    "filename": "mr-in-g1.utb",
                    "computer": false,
                    "language": "mr"
                },
                "ms-my-g2.ctb": {
                    "filename": "ms-my-g2.ctb",
                    "displayName": "Malay braille",
                    "indexName": "Malay",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "language": "ms",
                    "contraction": "full"
                },
                "mt.ctb": {
                    "filename": "mt.ctb",
                    "computer": false,
                    "language": "mt"
                },
                "mun.ctb": {
                    "filename": "mun.ctb",
                    "computer": false,
                    "language": "mun"
                },
                "mwr.ctb": {
                    "filename": "mwr.ctb",
                    "computer": false,
                    "language": "mwr"
                },
                "my-g1.utb": {
                    "filename": "my-g1.utb",
                    "displayName": "Burmese uncontracted braille",
                    "indexName": "Burmese, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "updated": "Tue Nov 17 00:00:00 CET 2020",
                    "language": "my",
                    "contraction": "no"
                },
                "my-g2.ctb": {
                    "filename": "my-g2.ctb",
                    "displayName": "Burmese contracted braille",
                    "indexName": "Burmese, contracted",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "updated": "Tue Nov 17 00:00:00 CET 2020",
                    "language": "my",
                    "contraction": "full"
                },
                "ne.ctb": {
                    "filename": "ne.ctb",
                    "computer": false,
                    "language": "ne"
                },
                "nl-NL-g0.utb": {
                    "filename": "nl-NL-g0.utb",
                    "displayName": "Dutch braille",
                    "indexName": "Dutch",
                    "computer": false,
                    "grade": "0",
                    "language": "nl",
                    "contraction": "no"
                },
                "nl-comp8.utb": {
                    "filename": "nl-comp8.utb",
                    "displayName": "Dutch computer braille",
                    "indexName": "Dutch, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "nl"
                },
                "no-no-8dot-fallback-6dot-g0.utb": {
                    "filename": "no-no-8dot-fallback-6dot-g0.utb",
                    "displayName": "Norwegian 8-dot uncontracted braille with 6-dot fallback",
                    "nativeName": "Norsk litterær punktskrift, 8-punkt med 6-punktsdefinisjoner i reserve",
                    "indexName": "Norwegian, uncontracted, 8-dot, 6-dot fallback",
                    "computer": false,
                    "grade": "0",
                    "dots": 8,
                    "language": "nn",
                    "contraction": "no"
                },
                "no-no-8dot.utb": {
                    "filename": "no-no-8dot.utb",
                    "displayName": "Norwegian 8-dot uncontracted braille",
                    "nativeName": "Norsk litterær punktskrift, 8-punkt",
                    "indexName": "Norwegian, uncontracted, 8-dot",
                    "computer": false,
                    "grade": "0",
                    "dots": 8,
                    "language": "nn",
                    "contraction": "no"
                },
                "no-no-comp8.ctb": {
                    "filename": "no-no-comp8.ctb",
                    "displayName": "Norwegian computer braille",
                    "nativeName": "Norsk datapunktskrift, 8-punkt",
                    "indexName": "Norwegian, computer",
                    "computer": true,
                    "grade": "0",
                    "dots": 8,
                    "language": "nn"
                },
                "no-no-g0.utb": {
                    "filename": "no-no-g0.utb",
                    "displayName": "Norwegian 6-dot uncontracted braille",
                    "nativeName": "Norsk litterær punktskrift, 6-punkt, fullskrift",
                    "indexName": "Norwegian, uncontracted, 6-dot",
                    "computer": false,
                    "grade": "0",
                    "dots": 6,
                    "language": "nn",
                    "contraction": "no"
                },
                "no-no-g1.ctb": {
                    "filename": "no-no-g1.ctb",
                    "displayName": "Norwegian grade 1 contracted braille",
                    "nativeName": "Norsk litterær punktskrift, 6-punkt, kortskrift nivå 1",
                    "indexName": "Norwegian, contracted, grade 1",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "nn",
                    "contraction": "partial"
                },
                "no-no-g2.ctb": {
                    "filename": "no-no-g2.ctb",
                    "displayName": "Norwegian grade 2 contracted braille",
                    "nativeName": "Norsk litterær punktskrift, 6-punkt, kortskrift nivå 2",
                    "indexName": "Norwegian, contracted, grade 2",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "language": "nn",
                    "contraction": "partial"
                },
                "no-no-g3.ctb": {
                    "filename": "no-no-g3.ctb",
                    "displayName": "Norwegian grade 3 contracted braille",
                    "nativeName": "Norsk litterær punktskrift, 6-punkt, kortskrift nivå 3",
                    "indexName": "Norwegian, contracted, grade 3",
                    "computer": false,
                    "grade": "3",
                    "dots": 6,
                    "language": "nn",
                    "contraction": "full"
                },
                "no-no-generic.ctb": {
                    "filename": "no-no-generic.ctb",
                    "computer": false,
                    "language": "nb"
                },
                "np-in-g1.utb": {
                    "filename": "np-in-g1.utb",
                    "computer": false,
                    "language": "np"
                },
                "nso-za-g1.utb": {
                    "filename": "nso-za-g1.utb",
                    "displayName": "Sepedi uncontracted braille",
                    "indexName": "Sepedi, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "nso",
                    "contraction": "no"
                },
                "nso-za-g2.ctb": {
                    "filename": "nso-za-g2.ctb",
                    "displayName": "Sepedi contracted braille",
                    "indexName": "Sepedi, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "nso",
                    "contraction": "full"
                },
                "or-in-g1.utb": {
                    "filename": "or-in-g1.utb",
                    "computer": false,
                    "language": "or"
                },
                "pi.ctb": {
                    "filename": "pi.ctb",
                    "computer": false,
                    "language": "pi"
                },
                "pl-custom-math.utb": {
                    "filename": "pl-custom-math.utb",
                    "displayName": "Custom Polish math notation, 6-dot (temporary)",
                    "nativeName": "Niestandardowy polski zapis matematyczny, 6-kropkowy (tymczasowy)",
                    "indexName": "Polish, custom math, 6-dot",
                    "computer": false,
                    "dots": 6,
                    "updated": "Wed Feb 21 00:00:00 CET 2024",
                    "language": "pl"
                },
                "pl-pl-comp8.ctb": {
                    "filename": "pl-pl-comp8.ctb",
                    "displayName": "Polish computer braille",
                    "indexName": "Polish, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "pl"
                },
                "pt-pt-comp8.ctb": {
                    "filename": "pt-pt-comp8.ctb",
                    "displayName": "Portuguese computer braille",
                    "indexName": "Portuguese, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "pt"
                },
                "pt-pt-g1.utb": {
                    "filename": "pt-pt-g1.utb",
                    "displayName": "Portuguese uncontracted braille",
                    "indexName": "Portuguese, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "pt",
                    "contraction": "no"
                },
                "pt-pt-g2.ctb": {
                    "filename": "pt-pt-g2.ctb",
                    "computer": false,
                    "language": "pt"
                },
                "pu-in-g1.utb": {
                    "filename": "pu-in-g1.utb",
                    "computer": false,
                    "language": "pu"
                },
                "ro-g0.utb": {
                    "filename": "ro-g0.utb",
                    "displayName": "Romanian braille",
                    "indexName": "Romanian, uncontracted",
                    "computer": false,
                    "grade": "0",
                    "dots": 6,
                    "language": "ro",
                    "contraction": "no"
                },
                "ro.ctb": {
                    "filename": "ro.ctb",
                    "computer": false,
                    "language": "ro"
                },
                "ru-compbrl.ctb": {
                    "filename": "ru-compbrl.ctb",
                    "displayName": "Russian braille for program sources",
                    "indexName": "Russian, for program sources",
                    "computer": false,
                    "grade": "0",
                    "language": "ru",
                    "contraction": "no"
                },
                "ru-litbrl-detailed.utb": {
                    "filename": "ru-litbrl-detailed.utb",
                    "displayName": "Russian braille with indication of capitals",
                    "indexName": "Russian, with capitals",
                    "computer": false,
                    "dots": 6,
                    "language": "ru",
                    "contraction": "no"
                },
                "ru-litbrl.ctb": {
                    "filename": "ru-litbrl.ctb",
                    "displayName": "Russian braille",
                    "indexName": "Russian",
                    "computer": false,
                    "dots": 6,
                    "language": "ru",
                    "contraction": "no"
                },
                "ru-ru-g1.ctb": {
                    "filename": "ru-ru-g1.ctb",
                    "displayName": "Russian contracted braille",
                    "indexName": "Russian, contracted",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "ru",
                    "contraction": "partial"
                },
                "ru.ctb": {
                    "filename": "ru.ctb",
                    "displayName": "Russian computer braille",
                    "indexName": "Russian, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "ru"
                },
                "rw-rw-g1.utb": {
                    "filename": "rw-rw-g1.utb",
                    "displayName": "Kinyarwanda braille",
                    "indexName": "Kinyarwanda",
                    "computer": false,
                    "language": "rw",
                    "contraction": "no"
                },
                "sa-in-g1.utb": {
                    "filename": "sa-in-g1.utb",
                    "computer": false,
                    "language": "sa"
                },
                "sah.utb": {
                    "filename": "sah.utb",
                    "displayName": "Yakut braille",
                    "indexName": "Yakut",
                    "computer": false,
                    "dots": 6,
                    "language": "sah",
                    "contraction": "no"
                },
                "se-se.ctb": {
                    "filename": "se-se.ctb",
                    "computer": false,
                    "language": "se"
                },
                "si-in-g1.utb": {
                    "filename": "si-in-g1.utb",
                    "computer": false,
                    "language": "si"
                },
                "sin.utb": {
                    "filename": "sin.utb",
                    "computer": false,
                    "language": "sin"
                },
                "sk-g1.ctb": {
                    "filename": "sk-g1.ctb",
                    "displayName": "Slovak braille",
                    "indexName": "Slovak",
                    "computer": false,
                    "dots": 6,
                    "language": "sk",
                    "contraction": "no"
                },
                "sk-sk-g1.utb": {
                    "filename": "sk-sk-g1.utb",
                    "computer": false,
                    "language": "sk"
                },
                "sk-sk.utb": {
                    "filename": "sk-sk.utb",
                    "computer": false,
                    "language": "sk"
                },
                "sl-si-comp8.ctb": {
                    "filename": "sl-si-comp8.ctb",
                    "displayName": "Slovenian computer braille",
                    "indexName": "Slovenian, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "sl"
                },
                "sl-si-g1.utb": {
                    "filename": "sl-si-g1.utb",
                    "computer": false,
                    "language": "sl"
                },
                "sot-za-g1.ctb": {
                    "filename": "sot-za-g1.ctb",
                    "displayName": "Sesotho uncontracted braille",
                    "indexName": "Sesotho, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "st",
                    "contraction": "no"
                },
                "sot-za-g2.ctb": {
                    "filename": "sot-za-g2.ctb",
                    "displayName": "Sesotho contracted braille",
                    "indexName": "Sesotho, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "st",
                    "contraction": "full"
                },
                "sr-Cyrl.ctb": {
                    "filename": "sr-Cyrl.ctb",
                    "displayName": "Serbian braille for Cyrillic script",
                    "indexName": "Serbian, Cyrillic",
                    "computer": false,
                    "language": "sr",
                    "contraction": "no"
                },
                "sr-g1.ctb": {
                    "filename": "sr-g1.ctb",
                    "displayName": "Serbian braille",
                    "indexName": "Serbian",
                    "computer": false,
                    "language": "sr",
                    "contraction": "no"
                },
                "sv-1989.ctb": {
                    "filename": "sv-1989.ctb",
                    "displayName": "Swedish computer braille (1989 standard)",
                    "indexName": "Swedish, computer, 1989",
                    "computer": true,
                    "dots": 8,
                    "language": "sv"
                },
                "sv-1996.ctb": {
                    "filename": "sv-1996.ctb",
                    "displayName": "Swedish computer braille (1996 standard)",
                    "indexName": "Swedish, computer, 1996",
                    "computer": true,
                    "dots": 8,
                    "language": "sv"
                },
                "sv-g0.utb": {
                    "filename": "sv-g0.utb",
                    "displayName": "Swedish uncontracted braille",
                    "nativeName": "Svensk 6-punkts fullskrift",
                    "indexName": "Swedish, uncontracted",
                    "computer": false,
                    "grade": "0",
                    "dots": 6,
                    "language": "sv",
                    "contraction": "no"
                },
                "sv-g1.ctb": {
                    "filename": "sv-g1.ctb",
                    "displayName": "Swedish partially contracted braille",
                    "nativeName": "Svensk 6-punkts förkortningstabell nivå 1",
                    "indexName": "Swedish, partially contracted",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "sv",
                    "contraction": "partial"
                },
                "sv-g2.ctb": {
                    "filename": "sv-g2.ctb",
                    "displayName": "Swedish contracted braille",
                    "nativeName": "Svensk 6-punkts förkortningstabell nivå 2",
                    "indexName": "Swedish, contracted",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "language": "sv",
                    "contraction": "full"
                },
                "syc.utb": {
                    "filename": "syc.utb",
                    "displayName": "Syriac braille",
                    "indexName": "Syriac",
                    "computer": false,
                    "dots": 6,
                    "language": "syr",
                    "contraction": "no"
                },
                "ta-ta-g1.ctb": {
                    "filename": "ta-ta-g1.ctb",
                    "displayName": "Tamil braille",
                    "indexName": "Tamil",
                    "computer": false,
                    "grade": "1",
                    "language": "ta",
                    "contraction": "no"
                },
                "ta.ctb": {
                    "filename": "ta.ctb",
                    "computer": false,
                    "language": "ta"
                },
                "te-in-g1.utb": {
                    "filename": "te-in-g1.utb",
                    "computer": false,
                    "language": "te"
                },
                "th-comp8-backward.utb": {
                    "filename": "th-comp8-backward.utb",
                    "displayName": "Thai computer braille",
                    "indexName": "Thai, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "th"
                },
                "th-g0.utb": {
                    "filename": "th-g0.utb",
                    "displayName": "Thai uncontracted braille",
                    "indexName": "Thai, uncontracted",
                    "computer": false,
                    "grade": "0",
                    "dots": 6,
                    "language": "th",
                    "contraction": "no"
                },
                "th-g1.utb": {
                    "filename": "th-g1.utb",
                    "displayName": "Thai partially contracted braille",
                    "indexName": "Thai, partially contracted",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "th",
                    "contraction": "partial"
                },
                "tr-g1.ctb": {
                    "filename": "tr-g1.ctb",
                    "displayName": "Turkish uncontracted braille",
                    "indexName": "Turkish, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "tr",
                    "contraction": "no"
                },
                "tr-g2.ctb": {
                    "filename": "tr-g2.ctb",
                    "computer": false,
                    "language": "tr"
                },
                "tr.ctb": {
                    "filename": "tr.ctb",
                    "computer": false,
                    "language": "tr"
                },
                "tsn-za-g1.ctb": {
                    "filename": "tsn-za-g1.ctb",
                    "displayName": "Setswana uncontracted braille",
                    "indexName": "Setswana, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "tn",
                    "contraction": "no"
                },
                "tsn-za-g2.ctb": {
                    "filename": "tsn-za-g2.ctb",
                    "displayName": "Setswana contracted braille",
                    "indexName": "Setswana, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "tn",
                    "contraction": "full"
                },
                "tt.utb": {
                    "filename": "tt.utb",
                    "displayName": "Tatar braille",
                    "indexName": "Tatar",
                    "computer": false,
                    "dots": 6,
                    "language": "tt",
                    "contraction": "no"
                },
                "uga.utb": {
                    "filename": "uga.utb",
                    "displayName": "Ugaritic braille",
                    "indexName": "Ugaritic",
                    "computer": false,
                    "dots": 6,
                    "language": "uga",
                    "contraction": "no"
                },
                "uk-comp.utb": {
                    "filename": "uk-comp.utb",
                    "displayName": "Ukrainian computer braille",
                    "indexName": "Ukrainian, computer",
                    "computer": true,
                    "dots": 8,
                    "language": "uk"
                },
                "uk-detailed.utb": {
                    "filename": "uk-detailed.utb",
                    "displayName": "Ukrainian braille with indication of capitals",
                    "indexName": "Ukrainian, with capitals",
                    "computer": false,
                    "dots": 6,
                    "language": "uk",
                    "contraction": "no"
                },
                "uk.utb": {
                    "filename": "uk.utb",
                    "displayName": "Ukrainian braille",
                    "indexName": "Ukrainian",
                    "computer": false,
                    "language": "uk"
                },
                "uz-g1.utb": {
                    "filename": "uz-g1.utb",
                    "displayName": "Uzbek braille",
                    "indexName": "Uzbek",
                    "computer": false,
                    "language": "uz"
                },
                "ve-za-g1.utb": {
                    "filename": "ve-za-g1.utb",
                    "displayName": "Tshivenda uncontracted braille",
                    "indexName": "Tshivenda, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "ve",
                    "contraction": "no"
                },
                "ve-za-g2.ctb": {
                    "filename": "ve-za-g2.ctb",
                    "displayName": "Tshivenda contracted braille",
                    "indexName": "Tshivenda, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "ve",
                    "contraction": "full"
                },
                "vi-cb8.utb": {
                    "filename": "vi-cb8.utb",
                    "displayName": "Vietnamese computer braille",
                    "indexName": "Vietnamese, computer",
                    "computer": true,
                    "dots": 8,
                    "updated": "Tue Jan 17 00:00:00 CET 2023",
                    "language": "vi",
                    "contraction": "no"
                },
                "vi-saigon-g1.ctb": {
                    "filename": "vi-saigon-g1.ctb",
                    "displayName": "Vietnamese Saigon braille",
                    "indexName": "Vietnamese, Saigon, contracted",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "updated": "Mon Jan 16 00:00:00 CET 2023",
                    "language": "vi",
                    "contraction": "full"
                },
                "vi-vn-g0.utb": {
                    "filename": "vi-vn-g0.utb",
                    "displayName": "Vietnamese uncontracted braille",
                    "indexName": "Vietnamese, uncontracted",
                    "computer": false,
                    "grade": "0",
                    "dots": 6,
                    "updated": "Tue Jan 17 00:00:00 CET 2023",
                    "language": "vi",
                    "contraction": "no"
                },
                "vi-vn-g1.ctb": {
                    "filename": "vi-vn-g1.ctb",
                    "displayName": "Vietnamese partially contracted braille",
                    "indexName": "Vietnamese, partially contracted",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "updated": "Mon Jan 16 00:00:00 CET 2023",
                    "language": "vi",
                    "contraction": "partial"
                },
                "vi-vn-g2.ctb": {
                    "filename": "vi-vn-g2.ctb",
                    "displayName": "Vietnamese contracted braille",
                    "indexName": "Vietnamese, contracted",
                    "computer": false,
                    "grade": "2",
                    "dots": 6,
                    "updated": "Mon Jan 16 00:00:00 CET 2023",
                    "language": "vi",
                    "contraction": "full"
                },
                "xh-za-g1.utb": {
                    "filename": "xh-za-g1.utb",
                    "displayName": "isiXhosa uncontracted braille",
                    "indexName": "isiXhosa, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "xh",
                    "contraction": "no"
                },
                "xh-za-g2.ctb": {
                    "filename": "xh-za-g2.ctb",
                    "displayName": "isiXhosa contracted braille",
                    "indexName": "isiXhosa, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "xh",
                    "contraction": "full"
                },
                "yi.utb": {
                    "filename": "yi.utb",
                    "displayName": "Yiddish braille",
                    "indexName": "Yiddish",
                    "computer": false,
                    "grade": "1",
                    "dots": 6,
                    "language": "yi",
                    "contraction": "no"
                },
                "zh-chn.ctb": {
                    "filename": "zh-chn.ctb",
                    "computer": false,
                    "language": "zh"
                },
                "zh-hk.ctb": {
                    "filename": "zh-hk.ctb",
                    "displayName": "Cantonese braille",
                    "indexName": "Cantonese",
                    "computer": false,
                    "language": "yue-HK"
                },
                "zh-tw.ctb": {
                    "filename": "zh-tw.ctb",
                    "displayName": "Taiwanese bopomofo braille",
                    "nativeName": "Taiwan zhuyin",
                    "indexName": "Mandarin, Taiwan, bopomofo",
                    "computer": false,
                    "language": "cmn-TW"
                },
                "zhcn-cbs.ctb": {
                    "filename": "zhcn-cbs.ctb",
                    "displayName": "Chinese common braille, for simplified Chinese characters",
                    "indexName": "Mandarin, mainland China, common braille scheme",
                    "computer": false,
                    "language": "cmn-CN"
                },
                "zhcn-g1.ctb": {
                    "filename": "zhcn-g1.ctb",
                    "displayName": "Chinese current braille with tones",
                    "indexName": "Mandarin, mainland China, with tones",
                    "computer": false,
                    "language": "cmn-CN"
                },
                "zhcn-g2.ctb": {
                    "filename": "zhcn-g2.ctb",
                    "displayName": "Chinese double-phonic braille",
                    "indexName": "Mandarin, mainland China, double-phonic",
                    "computer": false,
                    "language": "cmn-CN"
                },
                "zu-za-g1.utb": {
                    "filename": "zu-za-g1.utb",
                    "displayName": "isiZulu uncontracted braille",
                    "indexName": "isiZulu, uncontracted",
                    "computer": false,
                    "grade": "1",
                    "language": "zu",
                    "contraction": "no"
                },
                "zu-za-g2.ctb": {
                    "filename": "zu-za-g2.ctb",
                    "displayName": "isiZulu contracted braille",
                    "indexName": "isiZulu, contracted",
                    "computer": false,
                    "grade": "2",
                    "language": "zu",
                    "contraction": "full"
                }
            },
            "defaultTranslationTables": {
                "af": "afr-za-g1.ctb",
                "ar": "ar-ar-comp8.utb",
                "cs": "cs-g1.ctb",
                "da": "da-dk-g08.ctb",
                "de": "de-g0.utb",
                "el": "el.ctb",
                "en": "en-us-comp6.ctb",
                "es": "Es-Es-G0.utb",
                "fi": "fi.utb",
                "fr": "fr-bfu-comp6.utb",
                "hu": "en-us-comp6.ctb",
                "is": "is.ctb",
                "it": "it-it-comp6.utb",
                "lt": "lt-6dot.utb",
                "nb": "no-no-comp8.ctb",
                "nl": "nl-NL-g0.utb",
                "pl": "pl-pl-comp8.ctb",
                "pt": "pt-pt-comp8.ctb",
                "ru": "ru-litbrl.ctb",
                "sv": "se-se.ctb",
                "tr": "tr.ctb",
                "uk": "uk.utb"
            },
            "defaultDisplayTables": {
                "af": "unicode.dis",
                "ar": "unicode.dis",
                "cs": "unicode.dis",
                "da": "unicode.dis",
                "de": "unicode.dis",
                "el": "unicode.dis",
                "en": "unicode.dis",
                "es": "unicode.dis",
                "fi": "unicode.dis",
                "fr": "unicode.dis",
                "hu": "unicode.dis",
                "is": "unicode.dis",
                "it": "unicode.dis",
                "lt": "unicode.dis",
                "nb": "unicode.dis",
                "nl": "unicode.dis",
                "pl": "unicode.dis",
                "pt": "unicode.dis",
                "ru": "ru-unicode.dis",
                "sv": "unicode.dis",
                "tr": "unicode.dis",
                "uk": "ru-unicode.dis"
            },
            "displayTables": [
                "da-dk-octobraille.dis",
                "da-dk.dis",
                "de-de.dis",
                "de-eurobrl6.dis",
                "de-eurobrl6u.dis",
                "en-us-brf.dis",
                "es-new.dis",
                "es-old.dis",
                "hu-backtranslate-correction.dis",
                "nl-BE.dis",
                "no-no-braillo-047-01.dis",
                "no-no-generic.dis",
                "no-no.dis",
                "ru-letters.dis",
                "ru-ru.dis",
                "ru-unicode.dis",
                "se-se.dis",
                "text_nabcc.dis",
                "uni-text.dis",
                "unicode-without-blank.dis",
                "unicode.dis",
                "us-table.dis"
            ],
            "hyphenationDictionaries": {
                "de-g1-core-patterns.dic": "UTF-8",
                "de-g2-core-patterns.dic": "UTF-8",
                "hyph_brl_da_dk.dic": "UTF-8",
                "hyph_cs_CZ.dic": "UTF-8",
                "hyph_da_DK.dic": "UTF-8",
                "hyph_de_DE.dic": "ISO8859-1",
                "hyph_en_US.dic": "ISO8859-1",
                "hyph_eo.dic": "UTF-8",
                "hyph_es_ES.dic": "ISO8859-1",
                "hyph_fr_FR.dic": "ISO8859-1",
                "hyph_hu_HU.dic": "UTF-8",
                "hyph_it_IT.dic": "ISO8859-1",
                "hyph_nb_NO.dic": "ISO8859-1",
                "hyph_nl_NL.dic": "ISO8859-1",
                "hyph_nn_NO.dic": "ISO8859-1",
                "hyph_pl_PL.dic": "ISO8859-2",
                "hyph_pt_PT.dic": "ISO8859-1",
                "hyph_ru.dic": "UTF-8",
                "hyph_sv_SE.dic": "ISO8859-1"
            }
        },
        "Amazon Translate": {
            "canonicalName": "Amazon Translate",
            "names": [
                "Amazon Translate",
                "amazon-translate"
            ],
            "languages": [
                "af",
                "am",
                "ar",
                "az",
                "bg",
                "bn",
                "bs",
                "ca",
                "cs",
                "cy",
                "da",
                "de",
                "el",
                "en",
                "es",
                "es-MX",
                "et",
                "fa",
                "fa-AF",
                "fi",
                "fr",
                "fr-CA",
                "ga",
                "gu",
                "ha",
                "he",
                "hi",
                "hr",
                "ht",
                "hu",
                "hy",
                "id",
                "is",
                "it",
                "ja",
                "ka",
                "kk",
                "kn",
                "ko",
                "lt",
                "lv",
                "mk",
                "ml",
                "mn",
                "mr",
                "ms",
                "mt",
                "nb",
                "nl",
                "pa",
                "pl",
                "ps",
                "pt",
                "pt-PT",
                "ro",
                "ru",
                "si",
                "sk",
                "sl",
                "so",
                "sq",
                "sr",
                "sv",
                "sw",
                "ta",
                "te",
                "th",
                "tl",
                "tr",
                "uk",
                "ur",
                "uz",
                "vi",
                "zh",
                "zh-TW"
            ],
            "type": "TRANSLATION"
        }
    }
};

export { servicesAndLanguages };